import React from 'react';
import { useSelector } from 'react-redux';

import Filtros from '../../components/common/Filtros';
import ComboBox from '../../components/common/ComboBox';
import { useTablaFilter } from '../../components/common/Formulario';

import { usuarioModel } from '../../redux/models/usuario.model';
import { TYPE_FILTER } from '../../redux/types/app.types';

const UsuarioFilter = () => {
  const { estados } = useSelector((state) => state.maestro);
  const { getData, params, dispatch, setSelectedItem } = useTablaFilter();
  return (
    <Filtros
      onFilter={() => getData()}
      onNew={() => setSelectedItem(usuarioModel)}
    >
      <ComboBox
        name="eu"
        label="Estados de usuario"
        value={params.eu}
        placeholder="Todos"
        tabIndex={100}
        size={2}
        data={estados.map((i) => ({ value: i.dt, label: i.de }))}
        onChange={({ value }) => {
          dispatch({ type: TYPE_FILTER, payload: { eu: value } });
        }}
      />
      <ComboBox
        name="st"
        value={params.st}
        label="Estado del registro"
        placeholder="Todos"
        size={2}
        tabIndex={101}
        data={[
          { value: '1', label: 'Vigente' },
          { value: '0', label: 'No vigente' },
        ]}
        onChange={({ value }) => {
          dispatch({ type: TYPE_FILTER, payload: { st: value } });
        }}
      />
    </Filtros>
  );
};

export default UsuarioFilter;
