import React from 'react';

import Filtros from '../../components/common/Filtros';
import { useTablaFilter } from '../../components/common/Formulario';

import { maestroModel } from '../../redux/models/maestro.model';

const MaestroFilter = () => {
  const { getData, setSelectedItem } = useTablaFilter();
  return (
    <Filtros
      onFilter={() => getData()}
      onNew={() => setSelectedItem(maestroModel)}
      hideFilterButton
    ></Filtros>
  );
};

export default MaestroFilter;
