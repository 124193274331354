import React, { useEffect } from 'react';

import Loading from '../../components/common/Loading';
import TreeItem from '../../components/common/TreeItem';

const Branch = ({ menu, checkeable, list, onSelected }) => {
  if (menu.nf === false) {
    return (
      <TreeItem
        name={menu.no}
        id={menu.me}
        ac={menu.ac}
        icon={menu.im}
        checked={menu.st}
        checkeable={checkeable}
        onClick={() => onSelected(menu.ac)}
      />
    );
  }

  let hijos = list.filter((h) => {
    return h.pp === menu.mm;
  });

  hijos = hijos.map((h) => {
    if (h.nf === false) {
      return (
        <TreeItem
          key={h.me}
          name={h.no}
          ac={h.ac}
          id={h.me}
          icon={h.im}
          checked={h.st}
          checkeable={checkeable}
          onClick={() => onSelected(h.ac)}
        />
      );
    } else {
      return (
        <Branch
          menu={h}
          list={list}
          checkeable={checkeable}
          onSelected={(m) => onSelected(m)}
        />
      );
    }
  });

  return (
    <TreeItem
      name={menu.no}
      id={menu.me}
      ac={menu.ac}
      icon={menu.im}
      checked={menu.st}
      checkeable={checkeable}
      branch
      onClick={() => onSelected(menu.ac)}
    >
      {hijos}
    </TreeItem>
  );
};

const AccesoList = ({ accesos, processing, checkeable, onSelected }) => {
  let raices = accesos.filter((m) => {
    return m.ir;
  });

  let lista = raices.map((m) => {
    return (
      <Branch
        key={m.me}
        menu={m}
        list={accesos}
        checkeable={checkeable}
        onSelected={(ac) => {
          onSelected(ac);
        }}
      />
    );
  });

  useEffect(() => {
    let list = document.querySelectorAll(
      '.view-accesos ul.cb-tree-container li'
    );
    accesos.forEach((item) => {
      if (item.ir === false && item.st) {
        list.forEach((l) => {
          if (l.dataset.me === item.me) {
            l.querySelector('input').click();
          }
        });
      } else if (item.ir === true && item.st === true) {
        let hijos = document.querySelector(
          `.view-accesos ul.cb-tree-container li[data-me="${item.me}"]`
        );

        if (hijos != null && hijos.querySelector('ul') === null) {
          hijos.querySelector('input').click();
        }
      }
    });
  });

  return (
    <>
      {processing && <Loading />}
      <ul className="cb-tree-container cb-tree-children">{lista}</ul>
    </>
  );
};

export default AccesoList;
