import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Inicio from '../../pages/Inicio';
import Acceso from '../../pages/acceso/Acceso';
import Aplicacion from '../../pages/aplicacion/Aplicacion';
import Blog from '../../pages/blog/Blog';
import Cambio from '../../pages/Cambio';
import Opcion from '../../pages/opcion/Opcion';
import Permiso from '../../pages/permiso/Permiso';
import Rol from '../../pages/rol/Rol';
import Usuario from '../../pages/usuario/Usuario';
import Cliente from '../../pages/cliente/Cliente';
import Maestro from '../../pages/maestro/Maestro';

import '../../css/app/body.css';

const Body = () => {
  const { headers, headerSelected, openMenu } = useSelector(
    (state) => state.app
  );
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(
      headers.map((head) => {
        switch (head.mm) {
          case 'SEG_MEN':
            return (
              <div
                key={head.mm}
                className={`cb-content-component${
                  headerSelected && headerSelected.mm === head.mm
                    ? ''
                    : ' cb-close'
                }`}
              >
                <Opcion />
              </div>
            );
          case 'SEG_ACC':
            return (
              <div
                key={head.mm}
                className={`cb-content-component${
                  headerSelected && headerSelected.mm === head.mm
                    ? ''
                    : ' cb-close'
                }`}
              >
                <Acceso />
              </div>
            );
          case 'SEG_PER':
            return (
              <div
                key={head.mm}
                className={`cb-content-component${
                  headerSelected && headerSelected.mm === head.mm
                    ? ''
                    : ' cb-close'
                }`}
              >
                <Permiso />
              </div>
            );
          case 'PER_USU':
            return (
              <div
                key={head.mm}
                className={`cb-content-component${
                  headerSelected && headerSelected.mm === head.mm
                    ? ''
                    : ' cb-close'
                }`}
              >
                <Usuario />
              </div>
            );
          case 'PER_ROL':
            return (
              <div
                key={head.mm}
                className={`cb-content-component${
                  headerSelected && headerSelected.mm === head.mm
                    ? ''
                    : ' cb-close'
                }`}
              >
                <Rol />
              </div>
            );
          case 'SEG_APP':
            return (
              <div
                key={head.mm}
                className={`cb-content-component${
                  headerSelected && headerSelected.mm === head.mm
                    ? ''
                    : ' cb-close'
                }`}
              >
                <Aplicacion />
              </div>
            );
          case 'SEG_CAM':
            return (
              <div
                key={head.mm}
                className={`cb-content-component${
                  headerSelected && headerSelected.mm === head.mm
                    ? ''
                    : ' cb-close'
                }`}
              >
                <Cambio />
              </div>
            );
          case 'SER_BLOG':
            return (
              <div
                key={head.mm}
                className={`cb-content-component${
                  headerSelected && headerSelected.mm === head.mm
                    ? ''
                    : ' cb-close'
                }`}
              >
                <Blog />
              </div>
            );
          case 'SER_CLIE':
            return (
              <div
                key={head.mm}
                className={`cb-content-component${
                  headerSelected && headerSelected.mm === head.mm
                    ? ''
                    : ' cb-close'
                }`}
              >
                <Cliente />
              </div>
            );
          case 'SEG_CABE':
            return (
              <div
                key={head.mm}
                className={`cb-content-component${
                  headerSelected && headerSelected.mm === head.mm
                    ? ''
                    : ' cb-close'
                }`}
              >
                <Maestro />
              </div>
            );
          default:
            return null;
        }
      })
    );
  }, [headers, headerSelected]);

  return (
    <div className={`cb-content-body${openMenu === true ? ' active' : ''}`}>
      <div className="cb-content-component">
        <Inicio />
      </div>
      {list}
    </div>
  );
};

export default Body;
