import { useState } from 'react';
import { api } from '../redux/api';
import { servicios } from '../redux/helpers';

const useRol = () => {
  const [roles, setRoles] = useState([]);
  const [processingRoles, setProcessingRoles] = useState(false);

  const getRoles = (ap, aa) => {
    setProcessingRoles(true);
    api
      .get(servicios.PERFIL, {
        params: { ap, aa, st: 1, page: 0, pageSize: 30 },
      })
      .then((response) => setRoles(response.data))
      .catch(() => setRoles([]))
      .finally(() => setProcessingRoles(false));
  };
  return { roles, getRoles, processingRoles };
};

export default useRol;
