import { constantes } from './constantes';
export const servicios = {
  AUTHENTICATION: `${constantes.MS_AUTENTICACION}/v1/autentificacion`,
  CAPTCHA: `${constantes.MS_AUTENTICACION}/v1/captchas`,
  MENU: `${constantes.MS_AUDITORIA}/v1/menues`,
  APLICACION: `${constantes.MS_AUTENTICACION}/v1/aplicaciones`,
  MAESTRO: `${constantes.MS_AUDITORIA}/v1/maestros`,
  PERFIL: `${constantes.MS_AUTENTICACION}/v1/roles`,
  USUARIO: `${constantes.MS_AUTENTICACION}/v1/usuarios`,
  ACCESO: `${constantes.MS_AUDITORIA}/v1/accesos`,
  PERMISO: `${constantes.MS_AUDITORIA}/v1/permisos`,
  ARCHIVO: `${constantes.MS_ZERO}/v1/archivos`,
  BLOG: `${constantes.MS_ZERO}/v1/blogs`,
  DETALLES: `${constantes.MS_ZERO}/v1/maestros`,
  CLIENTE: `${constantes.MS_AUTENTICACION}/v1/clientes`,
};
