import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Bloque from '../../components/common/Bloque';
import Boton from '../../components/common/Boton';
import ComboBox from '../../components/common/ComboBox';
import { mensajeMini, confirmacion } from '../../helpers/Mensajes';

import useAcceso from '../../hooks/useAcceso';

import AccesoList from './AccesoList';

import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';

import '../../css/pages/accesos.css';

const Acceso = () => {
  const [app, setApp] = useState('');
  const [name, setName] = useState('');
  const { aplicaciones } = useSelector((state) => state.aplicacion);
  const { accesos, processingAcceso, getAccesos, postAcceso } = useAcceso();

  const handleBuscar = () => {
    if (app === '') {
      mensajeMini('Seleccione aplicación', 'warning');
    } else {
      getAccesos(app);
    }
  };

  const handleSave = () => {
    if (app === '') {
      mensajeMini('Seleccione aplicación', 'warning');
    } else {
      let current = [];
      let list = document.querySelectorAll(
        '.view-accesos ul.cb-tree-container li'
      );
      list.forEach((item) => {
        current.push({
          ac: item.dataset.ac,
          me: item.dataset.me,
          ap: app,
          st: item.querySelector('input').checked,
        });
      });

      if (current.length === 0) {
        mensajeMini('No hay datos para enviar');
      } else {
        postAcceso(current);
      }
    }
  };

  return (
    <div className="cb-component view-accesos">
      <Bloque titulo="Filtros">
        <div className="cb-form">
          <ComboBox
            name="app"
            value={app}
            label="Aplicaci&oacute;n"
            data={aplicaciones.map((i) => ({
              value: i.ap,
              label: i.na,
            }))}
            size={2}
            tabIndex={1}
            onChange={({ value, label }) => {
              setApp(value);
              setName(label);
            }}
          />
          <div className="cb-form-group cb-col-2 acciones">
            <Boton
              onClick={handleBuscar}
              disabled={processingAcceso}
              icon={() => <SearchIcon />}
            >
              Buscar
            </Boton>
            <Boton
              className="cb-grabar"
              disabled={processingAcceso}
              icon={() => <SaveIcon />}
              onClick={() =>
                confirmacion(
                  '¿Deseas guardar?',
                  `Los accesos permitidos para la aplicación ${name}`
                )
                  .then(handleSave)
                  .catch(() => {})
              }
            >
              Grabar
            </Boton>
          </div>
          <AccesoList
            accesos={accesos}
            processing={processingAcceso}
            checkeable={true}
            onSelected={() => {}}
          />
        </div>
      </Bloque>
    </div>
  );
};

export default Acceso;
