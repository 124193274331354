export const opcionModel = {
  me: '',
  mm: '',
  ap: '',
  nf: '',
  op: '',
  mp: '',
  or: '',
  ac: '',
  na: '',
  im: '',
  pp: 'Raíz',
  st: false,
};
