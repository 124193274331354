import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Icon from '@mui/material/Icon';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import {
  eliminarHeaderAction,
  seleccionarHeaderAction,
} from '../../redux/reducers/app.reducer';

const HeaderItem = (props) => {
  const dispatch = useDispatch();
  const { headerSelected } = useSelector((state) => state.app);
  return (
    <div
      className={`cb-header-item${
        headerSelected
          ? headerSelected.mm === props.id
            ? ' selected'
            : ''
          : ''
      }`}
    >
      {props.icon && (
        <Icon
          style={{
            color: `${
              headerSelected && headerSelected.mm === props.id
                ? 'whitesmoke'
                : '#949494e2'
            }`,
          }}
        >
          {props.icon}
        </Icon>
      )}
      <label onClick={() => dispatch(seleccionarHeaderAction(props.id))}>
        {props.label}
      </label>
      <HighlightOffIcon
        onClick={() => dispatch(eliminarHeaderAction(props.id))}
        fontSize="small"
        title="cerrar"
        style={{
          cursor: 'pointer',
          color: `${
            headerSelected && headerSelected.mm === props.id
              ? 'tomato'
              : '#9b4444'
          }`,
        }}
      />
    </div>
  );
};

export default HeaderItem;
