import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { caracteresUnicode } from '../../helpers/Utilitarios';

import FindInPageIcon from '@mui/icons-material/FindInPage';
import Tooltip from './Tooltip';

const Texto = ({
  name,
  label,
  tabIndex,
  onChange,
  size,
  type,
  value,
  className,
  disabled,
  error,
  restrict,
  format,
  search,
  onSearch,
  icon,
  info,
  enabledPaste,
  ...rest
}) => {
  const [invalid, setInvalid] = useState();
  const handleKeyPress = (e) => {
    let re = null;
    switch (restrict) {
      case 'int':
        re = new RegExp('[0-9]');
        break;
      case 'money':
        re = new RegExp('[0-9.-]');
        break;
      case 'password':
        re = new RegExp('[0-9a-zA-Z#$%&+=\u0040]');
        break;
      default:
        re = new RegExp(`[a-zA-Z0-9-/.:+,;\_\\s${caracteresUnicode()}]`);
        break;
    }

    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  const handlePaste = (e) => {
    if (enabledPaste) {
    } else {
      e.preventDefault();
    }
  };

  const handleFormat = (e) => {
    let re = null;
    let message = null;
    switch (format) {
      case 'phone':
        re = new RegExp('[9][0-9]{8}');
        message = 'Teléfono incorrecto';
        break;
      case 'email':
        re = new RegExp(
          '[a-zA-Z0-9][a-zA-Z0-9._-]*@[a-zA-Z0-9][a-zA-Z0-9._-]*(\\.[a-zA-Z]{2,3})$'
        );
        message = 'correo incorrecto';
        break;
      default:
        break;
    }
    if (re && !re.test(e.target.value)) {
      setInvalid(message);
    } else {
      setInvalid(null);
    }
  };

  return (
    <div
      className={`cb-form-group${size ? ' cb-col-' + size : ''}${
        error || invalid ? ' error' : ''
      }${search ? ' searcheable' : ''}${icon ? ' icon' : ''}`}
    >
      <input
        name={name}
        className={`cb-form${className ? ' ' + className : ''}`}
        value={value == null ? '' : value}
        tabIndex={tabIndex}
        required="required"
        disabled={disabled}
        type={type}
        onChange={(e) => onChange(e.target)}
        onKeyPress={(e) => handleKeyPress(e)}
        onPaste={(e) => handlePaste(e)}
        onBlur={(e) => handleFormat(e)}
        {...rest}
      />
      <label>{label}</label>
      {search && (
        <FindInPageIcon
          fontSize="large"
          style={{ cursor: 'pointer' }}
          onClick={() => onSearch()}
        />
      )}
      {icon && <div className="icon">{icon()}</div>}
      <span className="bar bottom"></span>
      <span className="bar left"></span>
      <span className="bar right"></span>
      <span className="bar top"></span>
      {error && <span className="cb-form-error">{error}</span>}
      {invalid && error === undefined && (
        <span className="cb-form-error">{invalid}</span>
      )}
      {info && <Tooltip>{info}</Tooltip>}
    </div>
  );
};

Texto.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tabIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf([2, 3, 4, 5, 6]),
  type: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  restrict: PropTypes.oneOf(['int', 'money', 'password', 'texto']),
  format: PropTypes.oneOf(['phone', 'email', 'none']),
  search: PropTypes.bool,
  onSearch: PropTypes.func,
  icon: PropTypes.func,
  focus: PropTypes.bool,
  info: PropTypes.string,
  enabledPaste: PropTypes.bool,
};

Texto.defaultProps = {
  type: 'text',
  disabled: false,
  restrict: 'texto',
  enabledPaste: false,
  format: 'none',
};

export default Texto;
