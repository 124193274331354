import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  eliminarHeaderAction,
  eliminarTodosHeaderAction,
  seleccionarHeaderAction,
} from '../../redux/reducers/app.reducer';

import Icon from '@mui/material/Icon';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

const Lista = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { headers, headerSelected } = useSelector((state) => state.app);
  const [lista, setLista] = useState([]);

  useEffect(() => {
    setLista(
      headers.map((i) => {
        return (
          <div key={i.mm} className="op-lista-item">
            {i.im && (
              <Icon
                style={{
                  color: `${
                    headerSelected && headerSelected.mm === i.mm
                      ? '#1a95d5'
                      : '#cccccc'
                  }`,
                }}
              >
                {i.im}
              </Icon>
            )}
            <label
              onClick={() => {
                dispatch(seleccionarHeaderAction(i.mm));
                onClose();
              }}
              style={{
                cursor: 'pointer',
                color: `${
                  headerSelected && headerSelected.mm === i.mm
                    ? '#000000'
                    : '#cccccc'
                }`,
              }}
            >
              {i.op}
            </label>
            <HighlightOffIcon
              onClick={() => {
                dispatch(eliminarHeaderAction(i.mm));
              }}
              fontSize="small"
              title="cerrar"
              style={{
                cursor: 'pointer',
                color: `${
                  headerSelected && headerSelected.mm === i.mm
                    ? 'tomato'
                    : '#cccccc'
                }`,
                paddingLeft: '10px',
              }}
            />
          </div>
        );
      })
    );
  }, [headers, headerSelected]);

  return (
    <div className={`cb-content-info${open ? ' open' : ''}`}>
      <div className="cb-lista-item">
        <DeleteSweepIcon style={{ color: '#cccccc' }} />
        <label
          onClick={() => dispatch(eliminarTodosHeaderAction())}
          style={{ cursor: 'pointer' }}
        >
          Eliminar todos
        </label>
      </div>
      <div className="separador line"></div>
      {lista}
    </div>
  );
};

export default Lista;
