import React, { useState } from 'react';
import Proptypes from 'prop-types';

/**
 * Boton
 * @param {string} props.children - mensaje del boton.
 * @param {func} props.onClick - funcion que inicia el evento onClick.
 * @param {string} props.className - (opcional) clase adicional (op-grabar, op-cerrar).
 * @param {string} props.type - (opcional) tipo de boton, por defecto -> 'button'.
 * @param {string} props.style - (opcional) estilos adicional.
 * @param {string} props.disabled - (opcional) deshabilitar boton, por defecto -> false.
 * @param {func} props.icon - (opcional) funcion que retorne un elemento (span o i).
 */
const Boton = ({
  className,
  type,
  disabled,
  tabIndex,
  icon,
  children,
  onClick,
  dropdownable,
  showDropdownOnTop,
  actions,
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  const dropdown = () =>
    actions.map((item) => (
      <div
        key={item.key}
        onClick={() => {
          setOpen(false);
          item.onClick();
        }}
        className="cb-dropdown-item"
      >
        {item.icon && item.icon()}
        <span>{item.label}</span>
      </div>
    ));

  return (
    <button
      className={`cb-boton${className ? ' ' + className : ''}`}
      onClick={() => {
        dropdownable ? setOpen(!open) : onClick();
      }}
      type={type}
      disabled={disabled}
      tabIndex={tabIndex}
      {...rest}
    >
      {disabled && <i className="cb-loading-mini"></i>}
      {icon && icon()}
      {` ${children}`}
      {dropdownable && (
        <div
          className={`cb-dropdown-content${open ? ' open' : ''}${
            showDropdownOnTop ? ' top' : ''
          }`}
        >
          {dropdown()}
        </div>
      )}
    </button>
  );
};

Boton.propTypes = {
  children: Proptypes.string.isRequired,
  type: Proptypes.string,
  style: Proptypes.object,
  disabled: Proptypes.bool,
  className: Proptypes.string,
  onClick: Proptypes.func,
  tabIndex: Proptypes.number,
  icon: Proptypes.func,
  dropdownable: Proptypes.bool,
  actions: Proptypes.arrayOf(
    Proptypes.shape({
      key: Proptypes.string.isRequired,
      label: Proptypes.string.isRequired,
      onClick: Proptypes.func.isRequired,
      icon: Proptypes.func,
    })
  ),
  showDropdownOnTop: Proptypes.bool,
};

Boton.defaultProps = {
  type: 'button',
  disabled: false,
  dropdownable: false,
  actions: [],
  showDropdownOnTop: false,
};

export default Boton;
