import React from 'react';

import Bloque from '../../components/common/Bloque';
import CheckBox from '../../components/common/CheckBox';
import TablaBody from '../../components/common/TablaBody';
import TablaSearch from '../../components/common/TablaSearch';
import Paginacion from '../../components/common/Paginacion';
import { useTablaFilter } from '../../components/common/Formulario';

import EditIcon from '@mui/icons-material/Edit';

const AplicacionList = () => {
  const { setSelectedItem } = useTablaFilter();
  const columns = [
    {
      key: 'ap',
      align: 'center',
      content: (item) => (
        <EditIcon
          onClick={() => setSelectedItem(item)}
          style={{ color: 'green', cursor: 'pointer' }}
        />
      ),
    },
    {
      key: 'na',
      label: 'Aplicación',
    },
    {
      key: 'co',
      label: 'Perfil',
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];
  return (
    <Bloque titulo="Lista de menues">
      <div className="cb-table">
        <TablaSearch />
        <TablaBody columns={columns} />
        <Paginacion />
      </div>
    </Bloque>
  );
};

export default AplicacionList;
