import React from 'react';

import Formulario from '../../components/common/Formulario';
import AplicacionItem from './AplicacionItem';
import AplicacionList from './AplicacionList';

import { aplicacionModel } from '../../redux/models/aplicacion.model';
import { servicios } from '../../redux/helpers';

const Aplicacion = () => {
  const initialState = {
    serviceName: servicios.APLICACION,
    selected: aplicacionModel,
    errors: aplicacionModel,
    openModal: false,
  };

  return (
    <Formulario initialState={initialState}>
      <AplicacionList />
      <AplicacionItem />
    </Formulario>
  );
};

export default Aplicacion;
