import JSEncrypt from 'jsencrypt';

import PdfFile from '../assets/icon/pdf-file.svg';
import PngFile from '../assets/icon/png-file.svg';
import JpgFile from '../assets/icon/jpg-file.svg';
import ExcelFile from '../assets/icon/excel-file.svg';
import DocFile from '../assets/icon/docx-file.svg';

/**
 * Formatear date a string.
 * @param {date|number|string} fecha fecha a formatear
 * @param {string} texto respuesta si no hay fecha
 * @param {boolean} time mostrar tiempo (default = true)
 */
export const formatoFecha = (fecha, texto, time = true) => {
  if (fecha === null) return texto ? texto : 'Indefinido';
  if (fecha === '') return texto ? texto : 'Indefinido';
  var date = new Date(fecha);
  if (isNaN(date.getTime())) return texto ? texto : 'Indefinido';
  var day = date.getDate();
  var month = date.getMonth() + 1;
  var year = date.getFullYear();

  var hour = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();

  if (time) {
    return (
      (day < 10 ? '0' + day : day) +
      '/' +
      (month < 10 ? '0' + month : month) +
      '/' +
      year +
      ' ' +
      (hour < 10 ? '0' + hour : hour) +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes) +
      ':' +
      (seconds < 10 ? '0' + seconds : seconds)
    );
  } else {
    return (
      (day < 10 ? '0' + day : day) +
      '/' +
      (month < 10 ? '0' + month : month) +
      '/' +
      year
    );
  }
};

/**
 * Compara si la fecha1 es menor o igual que le fecha2.
 * @param {number | string | date} fecha1
 * @param {number | string | date} fecha2
 */
export const compararFechas = (fecha1, fecha2) => {
  if (fecha1 === null && fecha2 === null) {
    return false;
  }
  if (fecha1 === null) {
    return false;
  }
  let date1 = new Date(fecha1);
  let date2 = fecha2 ? new Date(fecha2) : new Date();

  return +date1 <= +date2;
};

export const encriptado = (data) => {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(process.env.AUDITOR);
  return encrypt.encrypt(data);
};

export const caracteresUnicode = () => {
  let str = '';
  str += '\u00e1'; //a con tilde
  str += '\u00e9'; //e con tilde
  str += '\u00ed'; //i con tilde
  str += '\u00f3'; //o con tilde
  str += '\u00fa'; //u con tilde
  str += '\u00c1'; //A con tilde
  str += '\u00c9'; //E con tilde
  str += '\u00cd'; //I con tilde
  str += '\u00d3'; //O con tilde
  str += '\u00da'; //U con tilde
  str += '\u00f1'; //enie
  str += '\u00d1'; //ENIE
  str += '\u0040'; //Arroba
  return str;
};

export const handleColor = (estado, texto) => {
  let color = '#000000';
  switch (estado) {
    case '3':
      color = 'red';
      break;
    case '2':
      color = 'orange';
      break;
    case '4':
      color = '#858585';
      break;
    case '5':
      color = '#0d6efd';
      break;
    default:
      color = '#198754';
      break;
  }

  return <span style={{ color: color }}>{texto}</span>;
};

export const obtenerIcono = (extension) => {
  let icono = PdfFile;
  switch (extension) {
    case 'pdf':
      icono = PdfFile;
      break;
    case 'png':
      icono = PngFile;
      break;
    case 'jpg':
      icono = JpgFile;
      break;
    case 'jpeg':
      icono = JpgFile;
      break;
    case 'xls':
      icono = ExcelFile;
      break;
    case 'xlsx':
      icono = ExcelFile;
      break;
    case 'doc':
      icono = DocFile;
      break;
    case 'docx':
      icono = DocFile;
      break;
    default:
      break;
  }
  return icono;
};
