import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HeaderItem from './HeaderItem';
import Info from './Info';
import Lista from './Lista';

import {
  seleccionarInicioAction,
  abrirMenuAction,
} from '../../redux/reducers/app.reducer';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FilterListIcon from '@mui/icons-material/FilterList';
import HomeIcon from '@mui/icons-material/Home';

import '../../css/app/header.css';
import '../../css/app/info.css';

const Header = () => {
  const dispatch = useDispatch();
  const { headers, openMenu } = useSelector((state) => state.app);
  const [open, setOpen] = useState(false);
  const [openLista, setOpenLista] = useState(false);
  const [checked, setChecked] = useState(openMenu);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(
      headers.map((item) => {
        return (
          <HeaderItem
            key={item.mm}
            id={item.mm}
            label={item.op}
            icon={item.im}
          />
        );
      })
    );
  }, [headers]);

  return (
    <div className={`cb-header${openMenu === true ? ' active' : ''}`}>
      <div className="cb-header-app">{items}</div>
      <div className="cb-header-actions">
        <HomeIcon
          fontSize="large"
          style={{ color: '#ffffff', cursor: 'pointer' }}
          onClick={() => dispatch(seleccionarInicioAction())}
        />
        <FilterListIcon
          className="listado"
          fontSize="large"
          style={{ color: '#ffffff', cursor: 'pointer' }}
          onClick={() => setOpenLista(!openLista)}
        />
        <AccountBoxIcon
          fontSize="large"
          style={{ color: '#ffffff', cursor: 'pointer' }}
          onClick={() => setOpen(!open)}
        />
        <div className="cb-bars">
          <input
            type="checkbox"
            id="checkMenubar"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <label
            htmlFor="checkMenubar"
            onClick={() => dispatch(abrirMenuAction())}
          >
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>
        <Info open={open} />
        <Lista open={openLista} onClose={() => setOpenLista(false)} />
      </div>
    </div>
  );
};

export default Header;
