import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MenuItem from './MenuItem';
import Loading from '../common/Loading';

import { getMenuesPermitidosAction } from '../../redux/actions/app.action';

import '../../css/app/menu.css';

const Menu = () => {
  const dispatch = useDispatch();
  const { menues, openMenu, processing } = useSelector((state) => state.app);
  const [raices, setRaices] = useState([]);

  useEffect(() => {
    dispatch(getMenuesPermitidosAction());
  }, []);

  useEffect(() => {
    setRaices(menues.filter((m) => m.opp));
  }, [menues]);

  let lista = raices.map((m) => {
    return <MenuItem key={m.me} menu={m} list={menues} even={true} />;
  });

  return (
    <div className={`cb-content-menu${openMenu === true ? ' active' : ''}`}>
      {processing && <Loading />}
      <ul>{lista}</ul>
    </div>
  );
};

export default Menu;
