import React from 'react';
import { useSelector } from 'react-redux';

import Filtros from '../../components/common/Filtros';
import ComboBox from '../../components/common/ComboBox';
import { useTablaFilter } from '../../components/common/Formulario';

import { rolModel } from '../../redux/models/rol.model';

const RolFilter = () => {
  const { aplicaciones } = useSelector((state) => state.aplicacion);
  const { getData, params, dispatch, setSelectedItem } = useTablaFilter();
  return (
    <Filtros onFilter={() => getData()} onNew={() => setSelectedItem(rolModel)}>
      <ComboBox
        name="ap"
        label="Aplicaci&oacute;n"
        value={params.app}
        placeholder="Todos"
        tabIndex={20}
        size={2}
        data={aplicaciones.map((i) => ({ value: i.ap, label: i.na }))}
        searcheable
        dropdownTop
        onChange={({ value }) => {
          dispatch({ type: 'FILTER_APP', payload: value });
        }}
      />
    </Filtros>
  );
};

export default RolFilter;
