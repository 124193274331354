import { createSlice } from '@reduxjs/toolkit';
import {
  postLoginAction,
  putCaptchaAction,
  getCaptchaAction,
  getMenuesPermitidosAction,
} from '../actions/app.action';

let initialState = {
  currentUser: null,
  auditoria: null,
  captcha: null,
  error: null,
  processing: false,
  loggedIn: false,
  headers: [],
  headerSelected: null,
  menues: [],
  openMenu: false,
  status: 'checking',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    isLogginIn(state) {
      let currentUser = localStorage.getItem('currentUser');
      if (currentUser) {
        state.currentUser = JSON.parse(currentUser);
        state.loggedIn = true;
        state.status = 'authenticated';
      } else {
        state.status = 'not-authenticated';
      }
    },
    logoutAction(state) {
      localStorage.removeItem('currentUser');
      state.currentUser = null;
      state.captcha = null;
      state.headers = [];
      state.menues = [];
      state.headerSelected = null;
      state.processing = false;
      state.loggedIn = false;
      state.status = 'not-authenticated';
    },
    seleccionarInicioAction(state) {
      state.headerSelected = null;
    },
    seleccionarHeaderAction(state, action) {
      let head = state.headers.find((item) => item.mm === action.payload);
      if (head) {
        state.headerSelected = head;
      }
    },
    eliminarHeaderAction(state, action) {
      state.headers = [
        ...state.headers.filter((item) => item.mm !== action.payload),
      ];
    },
    eliminarTodosHeaderAction(state) {
      state.headers = [];
    },
    agregarHeaderAction(state, action) {
      let head = state.headers.find((h) => h.mm === action.payload.mm);
      if (head) {
        state.headerSelected = head;
      } else {
        state.headers = [...state.headers, action.payload];
        state.headerSelected = action.payload;
      }
    },
    abrirMenuAction(state) {
      state.openMenu = !state.openMenu;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postLoginAction.fulfilled, (state, action) => {
        state.processing = false;
        state.loggedIn = true;
        state.error = null;
        state.currentUser = action.payload;
        state.captcha = null;
        state.status = 'authenticated';
      })
      .addCase(getCaptchaAction.fulfilled, (state, action) => {
        state.processing = false;
        state.captcha = action.payload;
      })
      .addCase(putCaptchaAction.fulfilled, (state, action) => {
        state.processing = false;
        state.captcha = action.payload;
      })
      .addCase(getMenuesPermitidosAction.fulfilled, (state, action) => {
        state.menues = action.payload ? action.payload : [];
        state.processing = false;
      })
      .addMatcher(
        (action) => action.type.match('^app.*.pending$'),
        (state) => {
          state.processing = true;
        }
      )
      .addMatcher(
        (action) => action.type.match('^app.*.rejected$'),
        (state, action) => {
          state.processing = false;
          if (action.payload.detail) {
            state.error = action.payload.payload;
          } else {
            state.captcha = {
              ct: action.payload.errors.ct,
              si: action.payload.errors.si,
              img: action.payload.errors.img,
            };
          }
        }
      );
  },
});

export const {
  isLogginIn,
  logoutAction,
  seleccionarInicioAction,
  seleccionarHeaderAction,
  eliminarHeaderAction,
  eliminarTodosHeaderAction,
  agregarHeaderAction,
  abrirMenuAction,
} = appSlice.actions;

export default appSlice.reducer;
