import React from 'react';
import PropTypes from 'prop-types';

const CheckBox = (props) => {
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.target.querySelector('input').click();
    }
  };
  return (
    <div className={`cb-content-checkbox${props.disabled ? ' disabled' : ''}`}>
      <label
        className="cb-checkbox-toogle"
        tabIndex={props.tabIndex}
        onKeyPress={(e) => handleKeyPress(e)}
      >
        <input
          type="checkbox"
          defaultChecked={props.checked}
          onChange={(e) =>
            props.onChange({ name: props.name, value: e.target.checked })
          }
        />
        <span className="cb-checkbox-cheker"></span>
        <span className="cb-checkbox-on">On</span>
        <span className="cb-checkbox-off">Off</span>
      </label>
    </div>
  );
};

CheckBox.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  tabIndex: PropTypes.number,
};

CheckBox.defaultProps = {
  checked: false,
};

export default CheckBox;
