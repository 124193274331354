import React from 'react';

import ComboBox from '../../components/common/ComboBox';
import Filtros from '../../components/common/Filtros';
import Texto from '../../components/common/Texto';
import { useTablaFilter } from '../../components/common/Formulario';

import { blogModel } from '../../redux/models/blog.model';
import { TYPE_FILTER } from '../../redux/types/app.types';

const BlogFilter = () => {
  const { getData, params, dispatch, setSelectedItem } = useTablaFilter();
  return (
    <Filtros
      onFilter={() => getData()}
      onNew={() => setSelectedItem(blogModel)}
    >
      <Texto
        name="fi"
        value={params.fi}
        label="Fecha de inicio"
        tabIndex={200}
        size={3}
        type={'date'}
        onChange={({ value }) => {
          dispatch({ type: TYPE_FILTER, payload: { fi: value } });
        }}
      />
      <Texto
        name="ff"
        value={params.ff}
        label="Fecha fin"
        tabIndex={201}
        size={3}
        type={'date'}
        onChange={({ value }) => {
          dispatch({ type: TYPE_FILTER, payload: { ff: value } });
        }}
      />
      <ComboBox
        name="st"
        label="Estado de registro"
        value={params.st}
        placeholder="Todos"
        tabIndex={202}
        size={3}
        data={[
          { value: '1', label: 'Vigente' },
          { value: '0', label: 'No Vigent' },
        ]}
        onChange={({ value }) => {
          dispatch({ type: TYPE_FILTER, payload: { st: value } });
        }}
      />
    </Filtros>
  );
};

export default BlogFilter;
