import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Bloque from '../../components/common/Bloque';
import ComboBox from '../../components/common/ComboBox';
import Boton from '../../components/common/Boton';
import CheckBox from '../../components/common/CheckBox';

import AccesoList from '../acceso/AccesoList';

import { mensajeMini, confirmacion } from '../../helpers/Mensajes';

import useAcceso from '../../hooks/useAcceso';
import usePermiso from '../../hooks/usePermiso';
import useRol from '../../hooks/useRol';

import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';

const Permiso = () => {
  const [app, setApp] = useState('');
  const [name, setName] = useState('');
  const [acc, setAcc] = useState('');
  const [rol, setRol] = useState('');

  const { aplicaciones } = useSelector((state) => state.aplicacion);
  const { accesos, processingAcceso, getAccesos } = useAcceso();
  const { permisos, getPermisos, processingPermisos, postPermisos } =
    usePermiso();
  const { roles, getRoles, processingRoles } = useRol();
  const [dataPermisos, setdataPermisos] = useState([]);

  useEffect(() => {
    setdataPermisos(permisos);
  }, [permisos]);

  const handleBuscar = () => {
    if (app === '') {
      mensajeMini('Seleccione aplicación', 'warning');
    } else {
      getAccesos(app);
      getRoles(app);
    }
  };

  const handleSelectedAcceso = (acc) => {
    setAcc(acc);
    let list = document.querySelectorAll(
      '.view-permisos ul.cb-tree-container li'
    );
    list.forEach((l) => {
      l.querySelector('label').classList.remove('selected');
      if (l.dataset.ac === acc) {
        l.querySelector('label').classList.add('selected');
      }
    });
    if (rol !== '') {
      getPermisos(acc, rol);
    }
  };

  const handleChangePerfil = ({ value, label }) => {
    setRol(value);
    setName(value !== '' ? label : '');
    if (value !== '' && acc !== '') {
      getPermisos(acc, value);
    }
  };

  const handleSavePermisos = () => {
    if (app === '') {
      mensajeMini('Seleccione aplicación', 'warning');
    } else if (acc === '') {
      mensajeMini('Seleccione un acceso', 'warning');
    } else if (rol === '') {
      mensajeMini('Seleccione un perfil', 'warning');
    } else {
      let currents = [];
      let list = document.querySelectorAll('.view-permisos .cb-permiso');
      list.forEach((item) => {
        currents.push({
          pe: item.dataset.pe,
          pa: acc,
          pr: rol,
          tp: item.dataset.tp,
          st: item.querySelector('input').checked,
        });
      });

      if (currents.length === 0) {
        mensajeMini('No hay permisos para enviar');
      } else {
        postPermisos(currents);
      }
    }
  };

  return (
    <div className="cb-component view-permisos">
      <Bloque titulo="Filtros">
        <div className="cb-form">
          <ComboBox
            name="app"
            value={app}
            label="Aplicaci&oacute;n"
            disabled={processingAcceso}
            data={aplicaciones.map((i) => ({
              value: i.ap,
              label: i.na,
            }))}
            size={2}
            tabIndex={1}
            onChange={({ value }) => setApp(value)}
          />
          <div
            className="cb-form-group cb-col-2"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px',
              alignItems: 'center',
              height: '50px',
            }}
          >
            <Boton
              onClick={handleBuscar}
              disabled={processingAcceso}
              icon={() => <SearchIcon />}
            >
              Buscar
            </Boton>
            <Boton
              className="cb-grabar"
              disabled={processingAcceso}
              icon={() => <SaveIcon />}
              onClick={() =>
                confirmacion(
                  '¿Deseas guardar?',
                  `Los permisos para el perfil ${name}`
                )
                  .then(handleSavePermisos)
                  .catch(() => {})
              }
            >
              Grabar
            </Boton>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ flexBasis: '50%' }}>
              <AccesoList
                accesos={accesos}
                processing={processingPermisos}
                checkeable={false}
                onSelected={handleSelectedAcceso}
              />
            </div>
            <div style={{ flexBasis: '50%' }}>
              <ComboBox
                name="rol"
                value={rol}
                label="Perfil"
                disabled={processingRoles}
                data={roles.map((i) => ({
                  value: i.ir,
                  label: i.nr,
                }))}
                tabIndex={4}
                onChange={handleChangePerfil}
                searcheable
                placeholder="Seleccione perfil"
                noOptionMessage="No hay perfiles para esta aplicaci&oacute;n"
              />
              <div>
                {permisos.length === 0 ? (
                  <span>{`No hay permisos para este perfil`}</span>
                ) : (
                  <>
                    <h3>Permisos</h3>
                    {dataPermisos.map((item) => {
                      return (
                        <div
                          key={item.tp}
                          className="cb-permiso"
                          data-tp={item.tp}
                          data-pe={item.pe}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            padding: '10px 0',
                          }}
                        >
                          <label>{item.pp}</label>
                          <CheckBox
                            name={item.pp}
                            onChange={() => {}}
                            checked={item.st}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Bloque>
    </div>
  );
};

export default Permiso;
