import { encriptado } from '../../helpers/Utilitarios';
import { api } from '../api';
import { servicios } from '../helpers';

export const postUsuarioService = async (user) => {
  return await api.post(servicios.USUARIO, user);
};

export const putUsuarioService = async (user) => {
  return await api.put(servicios.USUARIO, user);
};

export const patchReseteoPasswordUsuarioService = async (user) => {
  return await api.patch(`${servicios.USUARIO}/${user.us}/reseteo`, user);
};

export const patchCambioPasswordUsuarioService = async (user) => {
  let item = {
    us: user.us,
    ac: encriptado(user.ac),
    nu: encriptado(user.nu),
    nc: encriptado(user.nc),
  };
  return await api.patch(`${servicios.USUARIO}/${user.us}/cambio`, item);
};
