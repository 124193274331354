import React, { useState } from 'react';
import { mensaje } from '../helpers/Mensajes';
import { api } from '../redux/api';
import { servicios } from '../redux/helpers';

const usePermiso = () => {
  const [permisos, setPermisos] = useState([]);
  const [processingPermisos, setProcessingPermisos] = useState(false);

  const getPermisos = (ia, ir) => {
    setProcessingPermisos(true);
    api
      .get(servicios.PERMISO, { params: { ia, ir } })
      .then((response) => setPermisos(response))
      .catch(() => setPermisos([]))
      .finally(() => setProcessingPermisos(false));
  };

  const postPermisos = (permisos) => {
    setProcessingPermisos(true);
    api
      .post(servicios.PERMISO, permisos)
      .then(() => mensaje('Operación Satisfactoria'))
      .catch((error) => console.log(error.data))
      .finally(() => setProcessingPermisos(false));
  };
  return { permisos, processingPermisos, getPermisos, postPermisos };
};

export default usePermiso;
