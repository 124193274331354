import React from 'react';

import Formulario from '../../components/common/Formulario';
import { servicios } from '../../redux/helpers';
import { maestroModel } from '../../redux/models/maestro.model';

import MaestroFilter from './MaestroFilter';
import MaestroItem from './MaestroItem';
import MaestroList from './MaestroList';

const Maestro = () => {
  const initialState = {
    serviceName: servicios.DETALLES,
    selected: maestroModel,
    errors: maestroModel,
  };

  return (
    <Formulario initialState={initialState}>
      <MaestroList />
      <MaestroFilter />
      <MaestroItem />
    </Formulario>
  );
};

export default Maestro;
