import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Boton from '../../components/common/Boton';
import ComboBox from '../../components/common/ComboBox';
import Modal from '../../components/common/Modal';
import Texto from '../../components/common/Texto';
import { useFormularioContext } from '../../components/common/Formulario';

import useRol from '../../hooks/useRol';

import { confirmacion } from '../../helpers/Mensajes';
import { usuarioModel } from '../../redux/models/usuario.model';

import SaveIcon from '@mui/icons-material/Save';
import { servicios } from '../../redux/helpers';

const UsuarioItem = () => {
  const { currentUser } = useSelector((state) => state.app);
  const { documentos } = useSelector((state) => state.maestro);
  const {
    selected,
    openModal,
    processing,
    errors,
    callTransaction,
    cancelTransaction,
  } = useFormularioContext();

  const [usuario, setUsuario] = useState(selected);
  const { roles, getRoles } = useRol();

  useEffect(() => {
    getRoles();
  }, []);
  useEffect(() => {
    setUsuario(selected);

    let doc = documentos.find((x) => x.de === selected.dt);
    if (doc) {
      handleChange({ name: 'td', value: doc.dt });
    }
    selected.rls.forEach((x) => {
      let rol = roles.find((s) => x.nr === s.nr && x.na === s.na);
      if (rol) {
        x.ir = rol.ir;
        x.ap = rol.ap;
      }
    });
  }, [selected, openModal]);

  const handleChange = (item) => {
    const { name, value } = item;
    setUsuario((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (!openModal) {
    return null;
  }

  const handleSave = () => {
    let user = {
      ...usuario,
      ie: currentUser.ie,
    };
    callTransaction({
      method: usuario.us ? 'put' : 'post',
      url: servicios.USUARIO,
      data: user,
      model: usuarioModel,
    });
  };
  return (
    <Modal
      title={`${usuario.us ? 'Editar' : 'Nuevo'} usuario`}
      processing={processing}
      onActions={() => (
        <Boton
          className="cb-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={18}
          onClick={() =>
            confirmacion(
              '¿Desea guardar?',
              `${usuario.us ? 'La actualización de' : 'Un nuevo'} usuario`
            )
              .then(() => handleSave())
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() => cancelTransaction(usuarioModel)}
    >
      <Texto
        name="cu"
        value={usuario.cu}
        label="Correo"
        size={2}
        tabIndex={10}
        error={errors.cu}
        onChange={handleChange}
        enabledPaste
      />
      <ComboBox
        name="rls"
        values={usuario.rls.map((r) => `${r.ir}==${r.ap}`)}
        label="Roles de usuario"
        size={2}
        tabIndex={11}
        data={roles.map((i) => ({
          value: `${i.ir}==${i.ap}`,
          label: `${i.nr} - ${i.na}`,
        }))}
        multiple
        dropdownTop
        onChange={(item) => {
          let roles = item.map((r) => ({
            ir: r.value.split('==')[0],
            ap: r.value.split('==')[1],
            st: true,
          }));
          setUsuario((prev) => ({
            ...prev,
            rls: roles,
          }));
        }}
      />
      <ComboBox
        name="td"
        label="Tipo documento"
        value={usuario.td}
        tabIndex={12}
        size={2}
        data={documentos.map((i) => ({ value: i.dt, label: i.de }))}
        error={errors.td}
        onChange={handleChange}
      />
      <Texto
        name="nd"
        value={usuario.nd}
        label="N&uacute;mero documento"
        size={2}
        tabIndex={13}
        error={errors.nd}
        onChange={handleChange}
      />
      <Texto
        name="nn"
        value={usuario.nn}
        label="Primer nombre"
        size={2}
        tabIndex={14}
        error={errors.nn}
        onChange={handleChange}
      />
      <Texto
        name="sn"
        value={usuario.sn}
        label="Segundo nombre (opcional)"
        size={2}
        tabIndex={15}
        error={errors.sn}
        onChange={handleChange}
      />
      <Texto
        name="ap"
        value={usuario.ap}
        label="Apellido paterno"
        size={2}
        tabIndex={16}
        error={errors.ap}
        onChange={handleChange}
      />
      <Texto
        name="am"
        value={usuario.am}
        label="Apellido materno (opcional)"
        size={2}
        tabIndex={17}
        error={errors.am}
        onChange={handleChange}
      />
    </Modal>
  );
};

export default UsuarioItem;
