import React from 'react';

import Bloque from '../../components/common/Bloque';
import CheckBox from '../../components/common/CheckBox';
import TablaBody from '../../components/common/TablaBody';
import TablaSearch from '../../components/common/TablaSearch';
import Paginacion from '../../components/common/Paginacion';
import {
  useTablaFilter,
  useFormularioContext,
} from '../../components/common/Formulario';

import BlogImage from './BlogImage';

import { formatoFecha } from '../../helpers/Utilitarios';

import EditIcon from '@mui/icons-material/Edit';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const BlogList = () => {
  const { setSelectedItem, dispatch } = useTablaFilter();
  const { openModalImage } = useFormularioContext();
  const columns = [
    {
      key: 'bl',
      align: 'center',
      content: (item) => (
        <>
          <EditIcon
            onClick={() => setSelectedItem(item)}
            style={{ color: 'green', cursor: 'pointer' }}
          />
          <AddPhotoAlternateIcon
            onClick={() => dispatch({ type: 'ADD IMAGE', payload: item })}
            style={{ color: '#3285a8', cursor: 'pointer' }}
          />
        </>
      ),
    },
    {
      key: 'au',
      label: 'Autor',
    },
    {
      key: 'ti',
      label: 'Titulo',
    },
    {
      key: 'fc',
      label: 'Creación',
      content: (item) => formatoFecha(item.fc),
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];
  return (
    <>
      <Bloque titulo="Lista de blogs">
        <div className="cb-table">
          <TablaSearch />
          <TablaBody columns={columns} />
          <Paginacion />
        </div>
      </Bloque>
      {openModalImage && <BlogImage />}
    </>
  );
};

export default BlogList;
