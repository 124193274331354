import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Boton from '../../components/common/Boton';
import Busqueda from '../../components/common/Busqueda';
import CheckBox from '../../components/common/CheckBox';
import ComboBox from '../../components/common/ComboBox';
import Modal from '../../components/common/Modal';
import Texto from '../../components/common/Texto';
import Formulario, {
  useFormularioContext,
} from '../../components/common/Formulario';

import { confirmacion } from '../../helpers/Mensajes';
import { opcionModel } from '../../redux/models/opcion.model';

import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import TablaBody from '../../components/common/TablaBody';
import Paginacion from '../../components/common/Paginacion';
import { servicios } from '../../redux/helpers';

const OpcionItem = () => {
  const { aplicaciones } = useSelector((state) => state.aplicacion);
  const {
    selected,
    openModal,
    processing,
    errors,
    callTransaction,
    cancelTransaction,
  } = useFormularioContext();

  const [opcion, setOpcion] = useState(selected);
  const [raiz, setRaiz] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpcion(selected);
    setRaiz(selected.pp === 'Raíz');
    let app = aplicaciones.find((x) => x.na === selected.na);
    if (app) {
      handleChange({ name: 'ap', value: app.ap });
    }
  }, [selected, openModal]);

  const handleChange = (item) => {
    const { name, value } = item;
    setOpcion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (!openModal) {
    return null;
  }

  const handleSave = () => {
    callTransaction({
      method: 'post',
      url: servicios.MENU,
      data: opcion,
      model: opcionModel,
    });
  };

  const columns = [
    {
      key: 'me',
      label: 'Enviar',
      align: 'center',
      content: (item) => (
        <CheckIcon
          onClick={() => {
            handleChange({ name: 'mp', value: item.me });
            handleChange({ name: 'pp', value: item.op });
            setOpen(false);
          }}
          style={{ color: 'green', cursor: 'pointer' }}
        />
      ),
    },
    {
      key: 'mm',
      label: 'Código',
    },
    {
      key: 'op',
      label: 'Nombre',
    },
    {
      key: 'na',
      label: 'Aplicación',
    },
  ];

  const initialStateParent = {
    params: {
      nf: '1',
    },
  };

  return (
    <>
      <Modal
        title={`${opcion.me === '' ? 'Nueva' : 'Editar'} opción`}
        processing={processing}
        onActions={() => (
          <Boton
            className="cb-grabar"
            disabled={processing}
            icon={() => <SaveIcon />}
            onClick={() =>
              confirmacion(
                '¿Desea guardar?',
                `${
                  opcion.me === '' ? 'Una nueva' : 'Una actualización de una'
                } opción`
              )
                .then(() => handleSave())
                .catch(() => {})
            }
          >
            Guardar
          </Boton>
        )}
        onClose={() => cancelTransaction(openModal)}
      >
        <Texto
          name="mm"
          value={opcion.mm}
          label="C&oacute;digo"
          size={2}
          tabIndex={10}
          error={errors.mm}
          onChange={handleChange}
          enabledPaste
          autoFocus
        />
        <Texto
          name="op"
          value={opcion.op}
          label="Nombre"
          size={2}
          tabIndex={12}
          error={errors.op}
          onChange={handleChange}
        />
        <ComboBox
          name="ap"
          value={opcion.ap}
          label="Aplicaci&oacute;n"
          size={2}
          tabIndex={13}
          data={aplicaciones.map((i) => ({ value: i.ap, label: i.na }))}
          searcheable
          error={errors.ap}
          onChange={handleChange}
        />
        <ComboBox
          name="nf"
          value={opcion.nf}
          label="Menu / sub-menu"
          size={2}
          tabIndex={13}
          data={[
            { value: 'true', label: 'Menu' },
            { value: 'false', label: 'Sub-menu' },
          ]}
          error={errors.nf}
          onChange={handleChange}
        />
        <Texto
          name="or"
          value={opcion.or}
          label="Orden"
          size={2}
          tabIndex={14}
          restrict="int"
          error={errors.or}
          onChange={handleChange}
        />
        <Texto
          name="ac"
          value={opcion.ac}
          label="Acci&oacute;n"
          size={2}
          tabIndex={15}
          error={errors.ac}
          onChange={handleChange}
        />
        <Texto
          name="im"
          value={opcion.im}
          label="Icono"
          size={2}
          tabIndex={16}
          error={errors.im}
          onChange={handleChange}
          enabledPaste
        />
        {opcion.me && (
          <div className="cb-form-group cb-col-2 cb-checkbox-group">
            <span>Estado del registro</span>
            <CheckBox name="st" checked={opcion.st} onChange={handleChange} />
          </div>
        )}
        <Texto
          name="pp"
          value={opcion.pp}
          label="Padre"
          size={2}
          tabIndex={17}
          error={errors.mp}
          onChange={handleChange}
          search
          disabled
          onSearch={() => setOpen(true)}
        />
        <div className="cb-form-group cb-col-2 cb-checkbox-group">
          <span>Seleccionar menu padre como ra&iacute;z</span>
          <CheckBox
            name="chn"
            checked={raiz}
            onChange={() => {
              handleChange({ name: 'mp', value: '' });
              handleChange({ name: 'pp', value: `${!raiz ? 'Raíz' : ''}` });
              setRaiz(!raiz);
            }}
          />
        </div>
      </Modal>
      {open && (
        <Busqueda
          title="B&uacute;squeda de opciones padre"
          columns={columns}
          params={{ nf: '1' }}
          onDoubleClick={(item) => {
            handleChange({ name: 'mp', value: item.me });
            handleChange({ name: 'pp', value: item.op });
            setOpen(false);
          }}
          serviceName={servicios.MENU}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default OpcionItem;
