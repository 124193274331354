import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Boton from '../../components/common/Boton';
import CheckBox from '../../components/common/CheckBox';
import ComboBox from '../../components/common/ComboBox';
import Modal from '../../components/common/Modal';
import Texto from '../../components/common/Texto';
import { useFormularioContext } from '../../components/common/Formulario';

import { confirmacion } from '../../helpers/Mensajes';
import { rolModel } from '../../redux/models/rol.model';
import { servicios } from '../../redux/helpers';

import SaveIcon from '@mui/icons-material/Save';

const RolItem = () => {
  const { aplicaciones } = useSelector((state) => state.aplicacion);
  const { roles } = useSelector((state) => state.maestro);
  const {
    selected,
    openModal,
    processing,
    errors,
    callTransaction,
    cancelTransaction,
  } = useFormularioContext();

  const [rol, setRol] = useState(selected);

  useEffect(() => {
    setRol(selected);
    let app = aplicaciones.find((x) => x.na === selected.na);
    if (app) {
      handleChange({ name: 'ap', value: app.ap });
    }
    let per = roles.find((x) => x.de === selected.nt);
    if (per) {
      handleChange({ name: 'tr', value: per.dt });
    }
  }, [selected, openModal]);

  const handleChange = (item) => {
    const { name, value } = item;
    setRol((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (!openModal) {
    return null;
  }

  const handleSave = () => {
    callTransaction({
      method: 'post',
      url: servicios.PERFIL,
      data: rol,
      model: rolModel,
    });
  };
  return (
    <Modal
      title={`${rol.ir === '' ? 'Nuevo' : 'Editar'} perfil`}
      processing={processing}
      onActions={() => (
        <Boton
          className="cb-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={16}
          onClick={() =>
            confirmacion(
              '¿Desea guardar?',
              `${rol.ir === '' ? 'Un nuevo' : 'Una actualización de un'} perfil`
            )
              .then(() => handleSave())
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() => cancelTransaction(rolModel)}
    >
      <ComboBox
        name="ap"
        value={rol.ap}
        label="Aplicaci&oacute;n"
        size={2}
        tabIndex={10}
        data={aplicaciones.map((i) => ({ value: i.ap, label: i.na }))}
        searcheable
        error={errors.ap}
        onChange={handleChange}
      />
      <Texto
        name="nr"
        value={rol.nr}
        label="Nombre"
        size={2}
        tabIndex={12}
        error={errors.nr}
        onChange={handleChange}
      />
      <ComboBox
        name="tr"
        value={rol.tr}
        label="Tipo"
        size={2}
        tabIndex={13}
        data={roles.map((i) => ({ value: i.dt, label: i.de }))}
        error={errors.tr}
        onChange={handleChange}
      />
      {rol.ir && (
        <div className="cb-form-group cb-col-2 cb-checkbox-group">
          <span>Estado del registro</span>
          <CheckBox
            name="st"
            tabIndex={14}
            checked={rol.st}
            onChange={handleChange}
          />
        </div>
      )}
      <Texto
        name="dr"
        value={rol.dr}
        label="Descripci&oacute;n"
        tabIndex={15}
        error={errors.dr}
        onChange={handleChange}
      />
    </Modal>
  );
};

export default RolItem;
