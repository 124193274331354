import { createSlice } from '@reduxjs/toolkit';
import { getMaestroDetalleAction } from '../actions/maestro.action';

let initialState = {
  documentos: [],
  estados: [],
  roles: [],
  tipoRegistros: [],
  tipoPersona: [],
  processing: false,
};

export const maestroReducer = createSlice({
  name: 'maestro',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMaestroDetalleAction.pending, (state) => {
        state.processing = true;
      })
      .addCase(getMaestroDetalleAction.fulfilled, (state, action) => {
        state.processing = false;
        switch (action.payload.ic) {
          case 'ROLES':
            state.roles = action.payload.data;
            break;
          case 'DOCUMENTOS':
            state.documentos = action.payload.data;
            break;
          case 'ESTADOS':
            state.estados = action.payload.data;
            break;
          case 'TIPOREGISTROS':
            state.tipoRegistros = action.payload.data;
            break;
          case 'TIPOPERSONA':
            state.tipoPersona = action.payload.data;
            break;
          default:
            break;
        }
      })
      .addCase(getMaestroDetalleAction.rejected, (state) => {
        state.processing = false;
      });
  },
});

export default maestroReducer.reducer;
