import React from 'react';

import Formulario from '../../components/common/Formulario';
import { servicios } from '../../redux/helpers';
import { opcionModel } from '../../redux/models/opcion.model';
import { TYPE_FILTER } from '../../redux/types/app.types';

import OpcionFilter from './OpcionFilter';
import OpcionItem from './OpcionItem';
import OpcionList from './OpcionList';

const Opcion = () => {
  const initialState = {
    params: {
      app: '',
    },
    serviceName: servicios.MENU,
    selected: opcionModel,
    errors: opcionModel,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case TYPE_FILTER:
        return {
          ...state,
          params: { app: action.payload },
        };
      default:
        return state;
    }
  };
  return (
    <Formulario reducer={reducer} initialState={initialState}>
      <OpcionList />
      <OpcionFilter />
      <OpcionItem />
    </Formulario>
  );
};

export default Opcion;
