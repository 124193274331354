import { createSlice } from '@reduxjs/toolkit';
import { getDetalleAction } from '../actions/detalle.action';

let initialState = {
  categorias: [],
  segmentos: [],
  processing: false,
};

export const detalleReducer = createSlice({
  name: 'detalle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDetalleAction.pending, (state) => {
        state.processing = true;
      })
      .addCase(getDetalleAction.fulfilled, (state, action) => {
        state.processing = false;
        switch (action.payload.co) {
          case 'CATEGORIA':
            state.categorias = action.payload.data;
            break;
          case 'SEGMENTO':
            state.segmentos = action.payload.data;
            break;
          default:
            break;
        }
      })
      .addCase(getDetalleAction.rejected, (state) => {
        state.processing = false;
      });
  },
});

export default detalleReducer.reducer;
