import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Formulario, { actions } from '../../components/common/Formulario';
import { getMaestroDetalleAction } from '../../redux/actions/maestro.action';
import { servicios } from '../../redux/helpers';
import { clienteModel } from '../../redux/models/cliente.model';

import ClienteEdit from './ClienteEdit';
import ClienteFilter from './ClienteFilter';
import ClienteItem from './ClienteItem';
import ClienteList from './ClienteList';

const Cliente = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMaestroDetalleAction('ESTADOS'));
    dispatch(getMaestroDetalleAction('TIPOREGISTROS'));
    dispatch(getMaestroDetalleAction('TIPOPERSONA'));
    dispatch(getMaestroDetalleAction('DOCUMENTOS'));
  }, []);

  const initialState = {
    params: {
      tr: null,
      ec: null,
      st: null,
    },
    serviceName: servicios.CLIENTE,
    selected: clienteModel,
    errors: clienteModel,
    openModalEdit: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'FILTER_APP':
        return {
          ...state,
          params: {
            tr: action.payload.tr,
            ec: action.payload.ec,
            st: action.payload.st,
          },
        };
      case 'CLIENT_EDIT':
        return {
          ...state,
          selected: action.payload,
          errors: clienteModel,
          openModalEdit: true,
        };
      case actions.TRANSACTION_CANCEL:
        return {
          ...state,
          openModalEdit: false,
        };
      default:
        return state;
    }
  };
  return (
    <Formulario reducer={reducer} initialState={initialState}>
      <ClienteList />
      <ClienteFilter />
      <ClienteItem />
      <ClienteEdit />
    </Formulario>
  );
};

export default Cliente;
