import React from 'react';

import Bloque from '../../components/common/Bloque';
import CheckBox from '../../components/common/CheckBox';
import TablaBody from '../../components/common/TablaBody';
import TablaSearch from '../../components/common/TablaSearch';
import Paginacion from '../../components/common/Paginacion';
import { useFormularioContext } from '../../components/common/Formulario';
import { handleColor } from '../../helpers/Utilitarios';
import { confirmacion } from '../../helpers/Mensajes';

import { servicios } from '../../redux/helpers';
import { usuarioModel } from '../../redux/models/usuario.model';

import EditIcon from '@mui/icons-material/Edit';
import RestoreIcon from '@mui/icons-material/Restore';

const UsuarioList = () => {
  const { callTransaction, setSelectedItem } = useFormularioContext();

  const handleResetPassword = (user) => {
    callTransaction({
      method: 'patch',
      url: `${servicios.USUARIO}/${user.us}/reseteo`,
      data: user,
      model: usuarioModel,
    });
  };

  const columns = [
    {
      key: 'us',
      align: 'center',
      content: (item) => (
        <>
          <div title="Editar" style={{ display: 'inline-block' }}>
            <EditIcon
              onClick={() => setSelectedItem(item)}
              style={{ color: 'green', cursor: 'pointer' }}
            />
          </div>
          <div title="Reset" style={{ display: 'inline-block' }}>
            <RestoreIcon
              onClick={() =>
                confirmacion(
                  '¿Está seguro de resetear la contraseña del usuario?',
                  `El usuario debe revisar su correo`
                )
                  .then(() => handleResetPassword(item))
                  .catch(() => {})
              }
              style={{ color: 'skyblue', cursor: 'pointer' }}
            />
          </div>
        </>
      ),
    },
    {
      key: 'np',
      label: 'Nombre',
      content: (item) => handleColor(item.vm, item.np),
    },
    {
      key: 'cu',
      label: 'usuario',
      content: (item) => handleColor(item.vm, item.cu),
    },
    {
      key: 'de',
      label: 'Estado usuario',
      content: (item) => handleColor(item.vm, item.de),
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];

  return (
    <Bloque titulo="Lista de usuarios">
      <div className="cb-table">
        <TablaSearch />
        <TablaBody columns={columns} />
        <Paginacion />
      </div>
    </Bloque>
  );
};

export default UsuarioList;
