import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Formulario from '../../components/common/Formulario';
import { getMaestroDetalleAction } from '../../redux/actions/maestro.action';
import { usuarioModel } from '../../redux/models/usuario.model';
import { TYPE_FILTER } from '../../redux/types/app.types';

import UsuarioFilter from './UsuarioFilter';
import UsuarioList from './UsuarioList';
import UsuarioItem from './UsuarioItem';
import { servicios } from '../../redux/helpers';

const Usuario = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMaestroDetalleAction('DOCUMENTOS'));
    dispatch(getMaestroDetalleAction('ESTADOS'));
  }, []);

  const initialState = {
    params: {
      ir: '',
      eu: '',
      st: '',
    },
    serviceName: servicios.USUARIO,
    selected: usuarioModel,
    errors: usuarioModel,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case TYPE_FILTER:
        return {
          ...state,
          params: {
            ir: action.payload.ir,
            eu: action.payload.eu,
            st: action.payload.st,
          },
        };
      default:
        return state;
    }
  };
  return (
    <Formulario reducer={reducer} initialState={initialState}>
      <UsuarioList />
      <UsuarioFilter />
      <UsuarioItem />
    </Formulario>
  );
};

export default Usuario;
