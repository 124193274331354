import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
  getCaptchaAction,
  putCaptchaAction,
  postLoginAction,
} from '../redux/actions/app.action';

import Boton from '../components/common/Boton';
import Texto from '../components/common/Texto';
import { useForm } from '../hooks/useForm';

import '../css/pages/login.css';

const Login = () => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { processing, captcha, error } = useSelector((state) => state.app);
  const { us, pw, tx, onChange } = useForm({ us: '', pw: '', tx: '' });

  useEffect(() => {
    dispatch(getCaptchaAction());
  }, []);

  const handleLogin = () => {
    dispatch(postLoginAction({ us, pw, tx }));
  };

  return (
    <div className="cb-login">
      <form onSubmit={handleLogin}>
        <h1>Zero</h1>
        <Texto
          label={'Usuario'}
          name="us"
          value={us}
          tabIndex={1}
          onChange={({ value }) => onChange(value, 'us')}
          autoFocus
        />
        <Texto
          label="Contrase&ntilde;a"
          name="pw"
          type={`${visible ? 'text' : 'password'}`}
          value={pw}
          tabIndex={2}
          onChange={({ value }) => onChange(value, 'pw')}
          icon={() =>
            visible ? (
              <VisibilityIcon onClick={() => setVisible(false)} />
            ) : (
              <VisibilityOffIcon onClick={() => setVisible(true)} />
            )
          }
          restrict="password"
          enabledPaste
        />
        <div className="cb-form-group cb-captcha">
          {processing || captcha === null ? (
            <>
              <div></div>
              <i className="cb-loading"></i>
            </>
          ) : (
            <>
              <img
                alt="captcha"
                src={`data:image/jpg;base64,${captcha.img}`}
                style={{ cursor: 'pointer' }}
              ></img>
              <RefreshIcon
                fontSize="large"
                onClick={() => dispatch(putCaptchaAction(captcha.ct))}
              />
            </>
          )}
        </div>
        {processing === false && captcha && (
          <p>{`La imagen contiene ${captcha.si} caracteres`}</p>
        )}
        <Texto
          label="Escribe los caracteres"
          name="tx"
          value={tx}
          tabIndex={3}
          onChange={({ value }) => onChange(value, 'tx')}
        />
        <Boton
          className="cb-grabar"
          disabled={processing}
          onClick={handleLogin}
        >
          Inicia sesi&oacute;n
        </Boton>
        {error && <label className="cb-error">{error}</label>}
        <h5>@2022 Finanser - Todos los derechos reservados</h5>
      </form>
    </div>
  );
};

export default Login;
