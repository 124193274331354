import React from 'react';
import { useSelector } from 'react-redux';

import Filtros from '../../components/common/Filtros';
import ComboBox from '../../components/common/ComboBox';
import { useTablaFilter } from '../../components/common/Formulario';

import { clienteModel } from '../../redux/models/cliente.model';

const ClienteFilter = () => {
  const { tipoRegistros, estados } = useSelector((state) => state.maestro);
  const { getData, params, dispatch, setSelectedItem } = useTablaFilter();
  return (
    <Filtros
      onFilter={() => getData()}
      onNew={() => setSelectedItem(clienteModel)}
    >
      <ComboBox
        name="ec"
        label="Estados"
        value={params.ec}
        placeholder="Todos"
        tabIndex={20}
        size={2}
        data={estados.map((i) => ({ value: i.dt, label: i.de }))}
        onChange={({ value }) => {
          dispatch({ type: 'FILTER_APP', payload: { ec: value } });
        }}
      />
      <ComboBox
        name="tr"
        label="Tipo registros"
        value={params.tr}
        placeholder="Todos"
        tabIndex={20}
        size={2}
        data={tipoRegistros.map((i) => ({ value: i.dt, label: i.de }))}
        onChange={({ value }) => {
          dispatch({ type: 'FILTER_APP', payload: { tr: value } });
        }}
      />
    </Filtros>
  );
};

export default ClienteFilter;
