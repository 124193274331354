import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../api';
import { servicios } from '../helpers';

export const getAplicacionesAction = createAsyncThunk(
  'aplicacion/getList',
  async (arg) => {
    let params = {
      page: 0,
      pageSize: 30,
    };
    try {
      let response = await api.get(servicios.APLICACION, { params });
      return response.data;
    } catch (error) {}
  }
);
