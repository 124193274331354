import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAplicacionesAction } from '../redux/actions/aplicacion.action';

import Header from '../components/app/Header';
import Menu from '../components/app/Menu';
import Body from '../components/app/Body';

import '../css/commons/bloque.css';
import '../css/commons/boton.css';
import '../css/commons/checkbox.css';
import '../css/commons/combo.css';
import '../css/commons/filtro.css';
import '../css/commons/fileupload.css';
import '../css/commons/formulario.css';
import '../css/commons/tabla.css';
import '../css/commons/modal.css';
import '../css/commons/tree.css';

const Home = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.app);

  useEffect(() => {
    if (currentUser.tr === 'NOBASICO') {
      dispatch(getAplicacionesAction());
    }
  }, []);

  return (
    <>
      <Header />
      <Menu />
      <Body />
    </>
  );
};

export default Home;
