import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TuneIcon from '@mui/icons-material/Tune';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';

const Filtros = (props) => {
  const [open, setOpen] = useState(true);

  const actionsButtons = () => (
    <>
      {props.actions && props.actions()}
      {props.hideSearchButton === undefined && (
        <YoutubeSearchedForIcon
          fontSize="large"
          onClick={() => props.onFilter()}
          style={{ cursor: 'pointer' }}
        />
      )}
      {props.hideNewButton === undefined && (
        <AddCircleOutlineIcon
          fontSize="large"
          onClick={() => props.onNew()}
          style={{ cursor: 'pointer' }}
        />
      )}
    </>
  );

  return (
    <div className={`cb-content-filtro${open ? '' : ' cb-close'}`}>
      <div className="cb-filtro">
        {props.hideFilterButton === undefined ? (
          <div
            className="cb-icono-filtro"
            title="Abrir filtros de b&uacute;squeda"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TuneIcon
              fontSize="large"
              onClick={() => setOpen(!open)}
              style={{ cursor: 'pointer' }}
            />
          </div>
        ) : (
          <div></div>
        )}
        <div className="cb-actions">{actionsButtons()}</div>
      </div>
      <div className={`cb-contenido`}>
        <div className="cb-form">{props.children}</div>
      </div>
    </div>
  );
};

Filtros.propTypes = {
  actions: PropTypes.func,
  hideNewButton: PropTypes.bool,
  hideSearchButton: PropTypes.bool,
  hideFilterButton: PropTypes.bool,
  onNew: PropTypes.func,
  onFilter: PropTypes.func,
};

export default Filtros;
