import { createAsyncThunk } from '@reduxjs/toolkit';
import { encriptado } from '../../helpers/Utilitarios';
import { mensajeMini } from '../../helpers/Mensajes';
import { api } from '../api';
import { servicios, constantes } from '../helpers/index';
import { logoutAction } from '../reducers/app.reducer';

export const postLoginAction = createAsyncThunk(
  'app/login',
  async (user, { rejectWithValue, getState, dispatch }) => {
    try {
      let { captcha } = getState().app;
      let usuario = {
        ...user,
        ap: constantes.APLICACION,
        ct: captcha.ct,
        pw: encriptado(user.pw),
      };
      const response = await api.post(
        `${servicios.AUTHENTICATION}/login`,
        usuario
      );
      localStorage.setItem('currentUser', JSON.stringify(response));
      return response;
    } catch (error) {
      if (error.data.detail) {
        dispatch(getCaptchaAction());
      }
      return rejectWithValue(error.data);
    }
  }
);

export const getCaptchaAction = createAsyncThunk(
  'app/captcha',
  async (arg, { rejectWithValue }) => {
    try {
      return await api.get(servicios.CAPTCHA);
    } catch (error) {
      return rejectWithValue(error.data.detail);
    }
  }
);

export const putCaptchaAction = createAsyncThunk(
  'app/reloadcaptcha',
  async (cp, { rejectWithValue }) => {
    try {
      return await api.put(`${servicios.CAPTCHA}/${cp}`);
    } catch (error) {
      return rejectWithValue(error.data.detail);
    }
  }
);

export const getMenuesPermitidosAction = createAsyncThunk(
  'app/menuesPermitidos',
  async (cp, { getState, dispatch }) => {
    try {
      let { currentUser } = getState().app;
      const params = {
        em: currentUser.ie,
        rl: currentUser.ir,
      };
      return await api.get(
        `${servicios.MENU}/${constantes.APLICACION}/aplicacion`,
        { params }
      );
    } catch (error) {
      if (error.status === 400 && error.data.errorCode === 1008) {
        mensajeMini(error.data.detail);
        dispatch(logoutAction());
      }
    }
  }
);
