import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../api';
import { servicios } from '../helpers';

export const getMaestroDetalleAction = createAsyncThunk(
  'maestro/getList',
  async (ic) => {
    let params = {
      page: 0,
      pageSize: 30,
    };
    try {
      let response = await api.get(`${servicios.MAESTRO}/${ic}/detalles`, {
        params,
      });
      return { ic, data: response };
    } catch (error) {}
  }
);
