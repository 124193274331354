import React, { useEffect, useState } from 'react';

import Boton from '../../components/common/Boton';
import CheckBox from '../../components/common/CheckBox';
import Modal from '../../components/common/Modal';
import Texto from '../../components/common/Texto';
import { useFormularioContext } from '../../components/common/Formulario';

import { confirmacion } from '../../helpers/Mensajes';
import { maestroModel } from '../../redux/models/maestro.model';
import { servicios } from '../../redux/helpers';

import SaveIcon from '@mui/icons-material/Save';

const MaestroItem = () => {
  const {
    selected,
    openModal,
    processing,
    errors,
    callTransaction,
    cancelTransaction,
  } = useFormularioContext();

  const [maestro, setMaestro] = useState(selected);

  useEffect(() => {
    setMaestro(selected);
  }, [selected, openModal]);

  const handleChange = (item) => {
    const { name, value } = item;
    setMaestro((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (!openModal) {
    return null;
  }

  const handleSave = () => {
    callTransaction({
      method: 'post',
      url: servicios.DETALLES,
      data: maestro,
      model: maestroModel,
    });
  };
  return (
    <Modal
      title={`${maestro.ma ? 'Editar' : 'Nuevo'} maestro`}
      processing={processing}
      onActions={() => (
        <Boton
          className="cb-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={16}
          onClick={() =>
            confirmacion(
              '¿Desea guardar?',
              `${maestro.ma ? 'Una actualización de un' : 'Un nuevo'} maestro`
            )
              .then(() => handleSave())
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() => cancelTransaction(maestroModel)}
    >
      <Texto
        name="co"
        value={maestro.co}
        label="C&oacute;digo"
        size={2}
        tabIndex={10}
        error={errors.co}
        onChange={handleChange}
      />
      <Texto
        name="vm"
        value={maestro.vm}
        label="Valor"
        size={2}
        tabIndex={11}
        error={errors.vm}
        onChange={handleChange}
      />
      <Texto
        name="ds"
        value={maestro.ds}
        label="Descripci&oacute;n"
        tabIndex={12}
        size={2}
        error={errors.ds}
        onChange={handleChange}
      />
      {maestro.ma && (
        <div className="cb-form-group cb-col-2 cb-checkbox-group">
          <span>Estado del registro</span>
          <CheckBox
            name="st"
            tabIndex={13}
            checked={maestro.st}
            onChange={handleChange}
          />
        </div>
      )}
    </Modal>
  );
};

export default MaestroItem;
