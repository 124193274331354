import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../api';
import { servicios } from '../helpers';

export const getDetalleAction = createAsyncThunk(
  'detalle/getList',
  async (co) => {
    try {
      let response = await api.get(`${servicios.DETALLES}/${co}`);
      return { co, data: response };
    } catch (error) {}
  }
);
