import React, { useEffect, useState } from 'react';

import Boton from '../../components/common/Boton';
import CheckBox from '../../components/common/CheckBox';
import Modal from '../../components/common/Modal';
import Texto from '../../components/common/Texto';
import { useFormularioContext } from '../../components/common/Formulario';

import { confirmacion } from '../../helpers/Mensajes';
import { aplicacionModel } from '../../redux/models/aplicacion.model';

import SaveIcon from '@mui/icons-material/Save';
import { servicios } from '../../redux/helpers';

const AplicacionItem = () => {
  const {
    selected,
    openModal,
    processing,
    errors,
    callTransaction,
    cancelTransaction,
  } = useFormularioContext();
  const [aplicacion, setAplicacion] = useState(selected);

  const handleChange = (item) => {
    const { name, value } = item;
    setAplicacion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    setAplicacion(selected);
  }, [selected]);

  if (!openModal) {
    return null;
  }

  const handleSave = () => {
    callTransaction({
      method: 'post',
      url: servicios.APLICACION,
      data: aplicacion,
      model: aplicacionModel,
    });
  };

  return (
    <Modal
      title={`${aplicacion.ap === '' ? 'Nueva' : 'Editar'} aplicación`}
      processing={processing}
      onActions={() => (
        <Boton
          className="cb-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={19}
          onClick={() =>
            confirmacion(
              '¿Desea guardar?',
              `${
                aplicacion.ap === '' ? 'Una nueva' : 'Una actualización de una'
              } aplicación`
            )
              .then(() => handleSave())
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() => cancelTransaction(aplicacionModel)}
    >
      <Texto
        name="na"
        value={aplicacion.na}
        label="Nombre"
        size={2}
        tabIndex={10}
        error={errors.na}
        onChange={handleChange}
      />
      <Texto
        name="ia"
        value={aplicacion.ia}
        label="Imagen"
        size={2}
        tabIndex={11}
        error={errors.ia}
        onChange={handleChange}
      />
      <Texto
        name="co"
        value={aplicacion.co}
        label="C&oacute;digo"
        size={2}
        tabIndex={12}
        error={errors.co}
        onChange={handleChange}
      />
      <Texto
        name="ua"
        value={aplicacion.ua}
        label="URL"
        tabIndex={15}
        size={2}
        error={errors.ua}
        onChange={handleChange}
      />
      <div className="op-form-group op-col-2 op-checkbox-group">
        <span>Estado del registro</span>
        <CheckBox
          name="st"
          tabIndex={16}
          checked={aplicacion.st}
          onChange={handleChange}
        />
      </div>
      <Texto
        name="da"
        value={aplicacion.da}
        label="Descripci&oacute;n"
        tabIndex={17}
        error={errors.da}
        onChange={handleChange}
      />
    </Modal>
  );
};

export default AplicacionItem;
