import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';

import Tooltip from './Tooltip';

const ComboBox = (props) => {
  const [seleccionado, setSeleccionado] = useState(false);
  const [valor, setValor] = useState({
    value: '',
    label: '',
  });
  const [on, setOn] = useState(false);
  const [filterTexto, setFilterText] = useState('');
  const [placeholder] = useState('Seleccione');
  const [multiple, setMultiple] = useState([]);
  const [currentOptions, setCurrentOptions] = useState([]);

  useEffect(() => {
    if (props.searcheable || props.multiple) {
      handleSelectedDefault();
      let encontrados = [];
      if (props.values.length > 0) {
        //Valores por defecto de selección multiple
        props.values.forEach((x) => {
          const v = props.data.find((y) => y.value === x);
          if (v != null) {
            encontrados.push(v);
          }
        });
        if (encontrados.length > 0) {
          setSeleccionado(true);
          setMultiple(encontrados);
        }
      }
      setCurrentOptions(
        props.data
          .filter((item) => {
            return encontrados.indexOf(item) < 0;
          })
          .map((item) => (
            <span
              key={item.value}
              className={handleIsSelected(item.value)}
              data-value={item.value}
              onClick={(e) => handleSelected(e)}
            >
              {item.label}
            </span>
          ))
      );
    } else {
      setCurrentOptions(props.data);
    }
  }, []);

  useEffect(() => {
    if (props.searcheable) {
      handleSelectedDefault();
    }
  }, [props.value]);

  useEffect(() => {
    if (props.multiple) {
      let encontrados = [];
      if (props.values.length > 0) {
        props.values.forEach((x) => {
          const v = props.data.find((y) => y.value === x);
          if (v != null) {
            encontrados.push(v);
          }
        });
        if (encontrados.length > 0) {
          setSeleccionado(true);
          setMultiple(encontrados);
        }
      } else {
        setSeleccionado(false);
        setMultiple([]);
      }

      setCurrentOptions(
        props.data
          .filter((item) => {
            return encontrados.indexOf(item) < 0;
          })
          .map((item) => (
            <span
              key={item.value}
              className={handleIsSelected(item.value)}
              data-value={item.value}
              onClick={(e) => handleSelected(e)}
            >
              {item.label}
            </span>
          ))
      );
    }
  }, [props.values]);

  useEffect(() => {
    if (props.searcheable || props.multiple) {
      setCurrentOptions(
        props.data.map((item) => (
          <span
            key={item.value}
            className={handleIsSelected(item.value)}
            data-value={item.value}
            onClick={(e) => handleSelected(e)}
          >
            {item.label}
          </span>
        ))
      );
    }
  }, [props.data]);

  useEffect(() => {
    if (props.searcheable || props.multiple) {
      if (filterTexto) {
        setCurrentOptions(
          props.data
            .filter((item) => {
              return (
                item.label.toLowerCase().indexOf(filterTexto.toLowerCase()) >= 0
              );
            })
            .map((item) => (
              <span
                key={item.value}
                className={handleIsSelected(item.value)}
                data-value={item.value}
                onClick={(e) => handleSelected(e)}
              >
                {item.label}
              </span>
            ))
        );
      } else {
        setCurrentOptions(
          props.data.map((item) => (
            <span
              key={item.value}
              className={handleIsSelected(item.value)}
              data-value={item.value}
              onClick={(e) => handleSelected(e)}
            >
              {item.label}
            </span>
          ))
        );
      }
    }
  }, [filterTexto]);

  const handleSelectedDefault = () => {
    const defecto = props.data.find((x) => x.value === props.value);
    if (defecto != null) {
      setSeleccionado(true);
      setValor({
        value: defecto.value,
        label: defecto.label,
      });
    }
  };

  function handleIsSelected(value) {
    let name = ' selected';

    if (props.multiple) {
      const v = multiple.find((y) => y.value === value);
      return v ? name : '';
    } else {
      return `${valor.value === value ? name : ''}`;
    }
  }

  const handleOpen = (e) => {
    setOn(!on);
    if (on == false) {
      e.preventDefault();
      let v = e.target.querySelector('input');
      if (v == null) {
        v = e.target.closest('.select-cb-control').querySelector('input');
      }
      setTimeout(() => {
        setFilterText('');
        v.focus();

        let listado = e.target
          .closest('.cb-form-group')
          .querySelector('.select-cb-list');
        if (listado) {
          if (listado.classList.contains('top')) {
            listado.style.top = `-${listado.offsetHeight + 10}px`;
          } else {
            listado.style.bottom = `-${listado.offsetHeight + 1}px`;
          }
        }
      }, 10);
    }
  };

  const handleSelected = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const value = e.target.dataset.value;
    const label = e.target.innerText;

    if (props.multiple == null) {
      e.target
        .closest('.select-cb-list')
        .querySelectorAll('span')
        .forEach((item) => {
          item.classList.remove('selected');
        });
    }
    e.target.classList.add('selected');
    setSeleccionado(true);

    if (props.multiple) {
      setTimeout(() => {
        setMultiple([...multiple, { value, label }]);
        props.onChange([...multiple, { value, label }]);
      }, 10);
    } else {
      setTimeout(() => {
        setValor({
          value,
          label,
        });
        props.onChange({ name: props.name, value, label });
      }, 10);
    }
    setOn(false);
  };

  const handleClear = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setValor({ value: '', label: '' });
    setMultiple([]);
    setSeleccionado(false);
    let listado = e.target
      .closest('.cb-form-group')
      .querySelector('.select-cb-list');

    if (listado) {
      setTimeout(() => {
        if (listado.classList.contains('top')) {
          listado.style.top = `-${listado.offsetHeight + 10}px`;
        } else {
          listado.style.bottom = `-${listado.offsetHeight + 1}px`;
        }
      }, 10);
    }

    if (props.multiple) {
      props.onChange([]);
    } else {
      props.onChange({ name: props.name, value: '', label: '' });
    }
  };

  const handleRemoveOne = (e, code) => {
    e.preventDefault();
    e.stopPropagation();

    let listado = e.target
      .closest('.cb-form-group')
      .querySelector('.select-cb-list');

    const currents = multiple.filter((x) => x.value !== code);
    setMultiple(currents);

    if (currents.length == 0) {
      setSeleccionado(false);
    }

    if (listado) {
      setTimeout(() => {
        if (listado.classList.contains('top')) {
          listado.style.top = `-${listado.offsetHeight + 10}px`;
        } else {
          listado.style.bottom = `-${listado.offsetHeight + 1}px`;
        }
      }, 10);
    }

    props.onChange(currents);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setFilterText(value);
    setOn(true);

    let listado = e.target
      .closest('.cb-form-group')
      .querySelector('.select-cb-list');

    if (listado) {
      setTimeout(() => {
        if (listado.classList.contains('top')) {
          listado.style.top = `-${listado.offsetHeight + 10}px`;
        } else {
          listado.style.bottom = `-${listado.offsetHeight + 1}px`;
        }
      }, 10);
    }
  };

  return (
    <div
      className={`cb-form-group${props.size ? ' cb-col-' + props.size : ''}${
        props.error ? ' error' : ''
      }`}
    >
      {props.searcheable || props.multiple ? (
        <>
          <div
            className={`select-cb-control${
              valor.value !== '' || multiple.length > 0 ? ' valid' : ''
            }${props.disabled ? ' disabled' : ''}`}
            onClick={(e) => handleOpen(e)}
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                e.preventDefault();
                e.stopPropagation();
                setOn(false);
              }
            }}
          >
            <div
              className={`select-cb-container${on ? ' on' : ''}${
                props.multiple ? ' multiple' : ''
              }`}
            >
              {seleccionado === false ? (
                <div className="select-cb-label placeholder">
                  {props.placeholder ? props.placeholder : placeholder}
                </div>
              ) : props.multiple ? (
                multiple.map((item) => {
                  return (
                    <div key={item.value} className="cb-multiple">
                      <div className="cb-label-multiple">{item.label}</div>
                      <div
                        className="cb-label-remove"
                        onClick={(e) => {
                          handleRemoveOne(e, item.value);
                        }}
                      >
                        x
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="select-cb-label single">{valor.label}</div>
              )}
              <div className="select-cb-search">
                <input
                  name={`${props.name}-search`}
                  placeholder={`${
                    props.placeholder ? props.placeholder : 'Buscar...'
                  }`}
                  tabIndex={props.tabIndex}
                  value={filterTexto}
                  onChange={(e) => handleSearch(e)}
                />
              </div>
            </div>
            <div className="select-cb-actions">
              {seleccionado == true && (
                <div
                  className="select-cb-action close"
                  onClick={(e) => handleClear(e)}
                ></div>
              )}
              <span className="select-cb-separador"></span>
              <div className="select-cb-action dropdown"></div>
            </div>
          </div>
          {on == true && (
            <div
              className={`select-cb-list${props.dropdownTop ? ' top' : ''}${
                props.multiple ? ' multiple' : ''
              }`}
            >
              {currentOptions.length > 0 ? (
                currentOptions
              ) : (
                <span className="empty">
                  {props.noOptionMessage
                    ? props.noOptionMessage
                    : `No hay coincidencias con '${filterTexto}'`}
                </span>
              )}
            </div>
          )}
          <label className={`${on ? 'on' : ''}`}>{props.label}</label>
          <input name={props.name} type="hidden" value={valor.value} />
        </>
      ) : (
        <>
          <select
            className="cb-form"
            value={props.value === null ? '' : props.value}
            name={props.name}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
            required="required"
            onChange={(e) =>
              props.onChange({
                name: props.name,
                value: e.target.value,
                label: e.target.options[e.target.selectedIndex].text,
              })
            }
            autoFocus={props.autoFocus}
          >
            <option value={''}>{`${
              props.placeholder ? props.placeholder : 'Seleccione'
            }`}</option>
            {currentOptions.map((item, i) => (
              <option key={i} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <label>{props.label}</label>
        </>
      )}
      {props.disabled && <i className="op-loading"></i>}
      <span className="bar bottom"></span>
      <span className="bar left"></span>
      <span className="bar right"></span>
      <span className="bar top"></span>
      {props.error && <span className="op-form-error">{props.error}</span>}
      {props.info && <Tooltip>{props.info}</Tooltip>}
    </div>
  );
};

ComboBox.propTypes = {
  name: Proptypes.string.isRequired,
  data: Proptypes.arrayOf(
    Proptypes.shape({
      value: Proptypes.string.isRequired,
      label: Proptypes.any.isRequired,
    })
  ).isRequired,
  label: Proptypes.string.isRequired,
  tabIndex: Proptypes.number.isRequired,
  onChange: Proptypes.func,
  getOptionValue: Proptypes.shape({
    value: Proptypes.string.isRequired,
    label: Proptypes.string.isRequired,
  }),
  disabled: Proptypes.bool,
  error: Proptypes.string,
  placeholder: Proptypes.string,
  size: Proptypes.oneOf([2, 3, 4]),
  value: Proptypes.any,
  values: Proptypes.arrayOf(Proptypes.any),
  searcheable: Proptypes.bool,
  multiple: Proptypes.bool,
  dropdownTop: Proptypes.bool,
  noOptionMessage: Proptypes.string,
  info: Proptypes.string,
};

ComboBox.defaultProps = {
  values: [],
};
export default ComboBox;
