export const clienteModel = {
  cl: null,
  pe: null,
  co: null,
  tf: null,
  es: null,
  fu: null,
  ic: null,
  tr: null,
  st: false,
};
