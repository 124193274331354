import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Boton from '../../components/common/Boton';
import { formatoFecha } from '../../helpers/Utilitarios';

import { logoutAction } from '../../redux/reducers/app.reducer';

import LogoutIcon from '@mui/icons-material/Logout';

const Info = ({ open }) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.app);
  return (
    <div className={`cb-content-info${open ? ' open' : ''}`}>
      <h3>Mi cuenta</h3>
      <span>{`Nombres : ${currentUser.np}`}</span>
      <span>{`Empresa : ${currentUser.ne}`}</span>
      <span>{`Rol : ${currentUser.nr}`}</span>
      <span>{`Último ingreso : ${formatoFecha(currentUser.fu)}`}</span>
      <div className="separador"></div>
      <Boton
        icon={() => <LogoutIcon fontSize="small" />}
        onClick={() => dispatch(logoutAction())}
      >
        Salir
      </Boton>
    </div>
  );
};

export default Info;
