import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import Boton from '../../components/common/Boton';
import CheckBox from '../../components/common/CheckBox';
import ComboBox from '../../components/common/ComboBox';
import Modal from '../../components/common/Modal';
import Texto from '../../components/common/Texto';
import { useFormularioContext } from '../../components/common/Formulario';

import { confirmacion } from '../../helpers/Mensajes';
import { blogModel } from '../../redux/models/blog.model';

import SaveIcon from '@mui/icons-material/Save';

import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { servicios } from '../../redux/helpers';

const BlogItem = () => {
  const { currentUser } = useSelector((state) => state.app);
  const { categorias, segmentos } = useSelector((state) => state.detalle);
  const {
    selected,
    openModal,
    processing,
    errors,
    callTransaction,
    cancelTransaction,
  } = useFormularioContext();

  const [blog, setBlog] = useState(selected);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    setBlog(selected);
    handleChange({ name: 'au', value: currentUser.np });
    let cate = categorias.find((x) => x.ds === selected.ct?.ds);
    if (cate) {
      handleChange({ name: 'cc', value: cate.ma });
    }
    let seg = segmentos.find((x) => x.ds === selected.sg?.ds);
    if (seg) {
      handleChange({ name: 'cs', value: seg.ma });
    }
    if (selected.co) {
      const contentBlock = htmlToDraft(selected.co);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    } else {
      setEditorState(() => EditorState.createEmpty());
    }
  }, [selected, openModal]);

  const handleChange = (item) => {
    const { name, value } = item;
    setBlog((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (!openModal) {
    return null;
  }

  const handleSave = () => {
    let item = {
      ...blog,
      co: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    };
    callTransaction({
      method: 'post',
      url: servicios.BLOG,
      data: item,
      model: blogModel,
    });
  };

  return (
    <Modal
      title={`${blog.bl ? 'Editar' : 'Nuevo'} blog`}
      processing={processing}
      onActions={() => (
        <Boton
          className="cb-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={14}
          onClick={() =>
            confirmacion(
              '¿Desea guardar?',
              `${blog.bl ? 'Una actualización de un' : 'Un nuevo'} blog`
            )
              .then(() => handleSave())
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() => cancelTransaction(blogModel)}
    >
      <Texto
        name="au"
        value={blog.au}
        label="Autor"
        size={2}
        tabIndex={10}
        disabled
        onChange={() => {}}
      />
      <Texto
        name="ti"
        value={blog.ti}
        label="Titulo"
        size={2}
        tabIndex={11}
        error={errors.ti}
        enabledPaste
        onChange={handleChange}
      />
      <Texto
        name="si"
        value={blog.si}
        label="Sub-Titulo"
        size={2}
        tabIndex={12}
        error={errors.si}
        enabledPaste
        onChange={handleChange}
      />
      {blog.bl && (
        <div className="cb-form-group cb-col-2 cb-checkbox-group">
          <span>Estado del registro</span>
          <CheckBox
            name="st"
            tabIndex={13}
            checked={blog.st}
            onChange={handleChange}
          />
        </div>
      )}
      <ComboBox
        name="cc"
        label="Categor&iacute;a"
        value={blog.cc}
        tabIndex={14}
        size={2}
        data={categorias.map((i) => ({ value: i.ma, label: i.ds }))}
        error={errors.cc}
        onChange={handleChange}
      />
      <ComboBox
        name="cs"
        label="Segmento"
        value={blog.cs}
        tabIndex={15}
        size={2}
        data={segmentos.map((i) => ({ value: i.ma, label: i.ds }))}
        error={errors.cs}
        onChange={handleChange}
      />
      <div className="cb-form-group">
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={(e) => setEditorState(e)}
        />
      </div>
    </Modal>
  );
};

export default BlogItem;
