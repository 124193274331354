import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Boton from '../../components/common/Boton';
import ComboBox from '../../components/common/ComboBox';
import Modal from '../../components/common/Modal';
import Texto from '../../components/common/Texto';
import { useFormularioContext } from '../../components/common/Formulario';

import useRol from '../../hooks/useRol';
import { confirmacion } from '../../helpers/Mensajes';
import { clienteModel } from '../../redux/models/cliente.model';
import { servicios } from '../../redux/helpers';

import SaveIcon from '@mui/icons-material/Save';

const ClienteEdit = () => {
  const { tipoPersona, documentos } = useSelector((state) => state.maestro);
  const {
    selected,
    openModalEdit,
    processing,
    errors,
    callTransaction,
    cancelTransaction,
  } = useFormularioContext();

  const [cliente, setCliente] = useState();
  const { aplicaciones } = useSelector((state) => state.aplicacion);
  const { roles, getRoles, processingRoles } = useRol();

  useEffect(() => {
    setCliente(selected);
    let tipo = tipoPersona.find((x) => x.de === selected.pe?.tp?.de);
    if (tipo) {
      handleChange({ name: 'cp', value: tipo.dt });
      handleChange({ name: 'ttt', value: tipo.vm });
    }
    let doc = documentos.find((x) => x.de === selected.pe?.td?.de);
    if (doc) {
      handleChange({ name: 'cd', value: doc.dt });
    }
    handleChange({ name: 'nn', value: selected.pe?.nn });
    handleChange({ name: 'sn', value: selected.pe?.sn });
    handleChange({ name: 'ap', value: selected.pe?.ap });
    handleChange({ name: 'am', value: selected.pe?.am });
    handleChange({ name: 'rz', value: selected.pe?.rz });

    if (selected.aa) {
      getRoles(null, selected.aa);
    }
  }, [selected, openModalEdit]);

  useEffect(() => {
    let pp = [];
    if (roles.length > 0) {
      pp = roles?.filter((x) => selected.ppr?.find((s) => s.nr == x.nr));
    }
    handleChange({ name: 'pp', value: pp });
  }, [roles]);

  const handleChange = (item) => {
    const { name, value } = item;
    setCliente((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (!openModalEdit) {
    return null;
  }

  const handleSave = () => {
    let item = {
      ...cliente,
      pp: cliente.pp?.map((r) => r.ir),
    };
    callTransaction({
      method: 'put',
      url: `${servicios.CLIENTE}`,
      data: item,
      model: clienteModel,
    });
  };
  return (
    <Modal
      title={`Editar cliente`}
      processing={processing}
      onActions={() => (
        <Boton
          className="cb-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={16}
          onClick={() =>
            confirmacion('¿Desea guardar?', `Una actualización de un cliente`)
              .then(() => handleSave())
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() => cancelTransaction(clienteModel)}
    >
      <ComboBox
        name="cp"
        value={cliente.cp}
        label="Tipo"
        size={2}
        tabIndex={10}
        data={tipoPersona.map((i) => ({ value: i.dt, label: i.de }))}
        onChange={(item) => {
          handleChange(item);
          let t = tipoPersona.find((x) => x.dt === item.value);
          handleChange({ name: 'ttt', value: t.vm });
        }}
      />
      <Texto
        name="co"
        value={cliente.co}
        label="Correo"
        size={2}
        tabIndex={11}
        format={'email'}
        error={errors.co}
        onChange={handleChange}
        enabledPaste
      />
      <ComboBox
        name="cd"
        label="Tipo documento"
        value={cliente.cd}
        tabIndex={12}
        size={2}
        data={documentos.map((i) => ({ value: i.dt, label: i.de }))}
        error={errors.cd}
        onChange={handleChange}
      />
      <Texto
        name="nd"
        value={cliente.nd}
        label="N&uacute;mero de documento"
        size={2}
        tabIndex={13}
        restrict={'int'}
        error={errors.nd}
        onChange={handleChange}
      />
      {cliente.ttt === 'N' ? (
        <>
          <Texto
            name="nn"
            value={cliente.nn}
            label="Primer nombre"
            size={2}
            tabIndex={12}
            error={errors.nn}
            onChange={handleChange}
          />
          <Texto
            name="sn"
            value={cliente.sn}
            label="Segundo nombre (opcional)"
            size={2}
            tabIndex={13}
            error={errors.sn}
            onChange={handleChange}
          />
          <Texto
            name="ap"
            value={cliente.ap}
            label="Apellido paterno"
            size={2}
            tabIndex={14}
            error={errors.ap}
            onChange={handleChange}
          />
          <Texto
            name="am"
            value={cliente.am}
            label="Apellido materno (opcional)"
            size={2}
            tabIndex={15}
            error={errors.am}
            onChange={handleChange}
          />
        </>
      ) : (
        <>
          <Texto
            name="rz"
            value={cliente.rz}
            label="Raz&oacute;n social"
            size={2}
            tabIndex={13}
            error={errors.rz}
            onChange={handleChange}
          />
        </>
      )}
      <Texto
        name="nt"
        value={cliente.nt}
        label="N&uacute;mero tel&eacute;fono"
        size={2}
        restrict={'int'}
        format={'phone'}
        tabIndex={16}
        error={errors.nt}
        onChange={handleChange}
      />
      <Texto
        name="es"
        value={cliente.es?.de}
        label="Estado"
        size={2}
        tabIndex={16}
        disabled
        onChange={() => {}}
      />
      <ComboBox
        name="aa"
        value={cliente.aa}
        label="Aplicaci&oacute;n"
        data={aplicaciones.map((i) => ({
          value: i.co,
          label: i.na,
        }))}
        size={2}
        tabIndex={18}
        onChange={(item) => {
          handleChange(item);
          handleChange({ name: 'pp', value: [] });
          if (item.value) {
            getRoles(null, item.value);
          }
        }}
      />
      <ComboBox
        name="pp"
        values={cliente.pp?.map((r) => r.ir)}
        label="Perfiles"
        size={2}
        tabIndex={19}
        data={roles.map((i) => ({
          value: i.ir,
          label: i.nr,
        }))}
        disabled={processingRoles}
        multiple
        dropdownTop
        onChange={(item) => {
          let list = item.map((r) => r.value);
          setCliente((prev) => ({
            ...prev,
            pp: roles.filter((x) => list.includes(x.ir)),
          }));
        }}
      />
      <Texto
        name="tr"
        value={cliente.tr?.de}
        label="Registro"
        size={2}
        tabIndex={20}
        disabled
        onChange={() => {}}
      />
    </Modal>
  );
};

export default ClienteEdit;
