import React from 'react';

import Bloque from '../../components/common/Bloque';
import TablaBody from '../../components/common/TablaBody';
import TablaSearch from '../../components/common/TablaSearch';
import Paginacion from '../../components/common/Paginacion';
import { useTablaFilter } from '../../components/common/Formulario';

import EditIcon from '@mui/icons-material/Edit';

const ClienteList = () => {
  const { dispatch } = useTablaFilter();
  const columns = [
    {
      key: 'cl',
      align: 'center',
      content: (item) => (
        <EditIcon
          onClick={() => dispatch({ type: 'CLIENT_EDIT', payload: item })}
          style={{ color: 'green', cursor: 'pointer' }}
        />
      ),
    },
    {
      key: 'pe',
      label: 'Cliente',
      content: (item) => <span>{item.pe.np}</span>,
    },
    {
      key: 'co',
      label: 'Correo',
    },
    {
      key: 'es',
      label: 'Estado',
      content: (item) => <span>{item.es.de}</span>,
    },
    {
      key: 'tr',
      label: 'Tipo registro',
      content: (item) => <span>{item.tr.de}</span>,
    },
  ];
  return (
    <Bloque titulo="Lista de clientes">
      <div className="cb-table">
        <TablaSearch />
        <TablaBody columns={columns} />
        <Paginacion />
      </div>
    </Bloque>
  );
};

export default ClienteList;
