import { configureStore } from '@reduxjs/toolkit';

import appSlice from './reducers/app.reducer';
import aplicacionReducer from './reducers/aplicacion.reducer';
import maestroReducer from './reducers/maestro.reducer';
import detalleReducer from './reducers/detalle.reducer';

export const store = configureStore({
  reducer: {
    app: appSlice,
    aplicacion: aplicacionReducer,
    maestro: maestroReducer,
    detalle: detalleReducer,
  },
});
