import React from 'react';
import { useSelector } from 'react-redux';

import Filtros from '../../components/common/Filtros';
import ComboBox from '../../components/common/ComboBox';
import { useTablaFilter } from '../../components/common/Formulario';

import { opcionModel } from '../../redux/models/opcion.model';
import { TYPE_FILTER } from '../../redux/types/app.types';

const OpcionFilter = () => {
  const { aplicaciones } = useSelector((state) => state.aplicacion);
  const { getData, params, dispatch, setSelectedItem } = useTablaFilter();

  return (
    <Filtros
      onFilter={() => getData()}
      onNew={() => setSelectedItem(opcionModel)}
    >
      <ComboBox
        name="ap"
        label="Aplicaci&oacute;n"
        value={params.app}
        placeholder="Todos"
        tabIndex={20}
        size={2}
        data={aplicaciones.map((i) => ({ value: i.ap, label: i.na }))}
        searcheable
        dropdownTop
        onChange={({ value }) => {
          dispatch({ type: TYPE_FILTER, payload: value });
        }}
      />
    </Filtros>
  );
};

export default OpcionFilter;
