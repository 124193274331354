export const usuarioModel = {
  us: '',
  ie: '',
  eu: '',
  td: '',
  nd: '',
  nn: '',
  sn: '',
  ap: '',
  am: '',
  cu: '',
  ad: '',
  st: false,
  fu: '',
  ic: '',
  rls: [],
};
