import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Icon from '@mui/material/Icon';

const TreeItem = (props) => {
  const [open, setOpen] = useState(true);

  const handleSelected = (e) => {
    let current = e.target;
    if (current.closest('li').querySelector('ul')) {
      let childrens = current
        .closest('li')
        .querySelector('ul')
        .querySelectorAll("input[type='checkbox']");
      childrens.forEach((item) => {
        item.checked = current.checked;
      });
    }
    if (
      current.closest('ul').classList.contains('cb-tree-container') === false
    ) {
      handleParentSelected(current);
    }
  };

  const handleParentSelected = (element) => {
    let hermanos = element
      .closest('ul')
      .querySelectorAll("input[type='checkbox']");
    let total = hermanos.length;
    let seleccionados = 0;
    hermanos.forEach((item) => {
      if (item.checked === true) {
        seleccionados++;
      }
    });

    let padre = element.closest('ul').closest('li').querySelector('input');
    if (element.checked === true && seleccionados > 0) {
      padre.checked = true;
    } else if (element.checked === false && seleccionados === 0) {
      padre.checked = false;
    }
  };
  return (
    <li
      data-ac={props.ac}
      data-me={props.id}
      className={`cb-tree-node ${
        props.branch ? 'cb-tree-branch cb-tree-open' : 'cb-tree-leaf'
      }`}
    >
      {props.branch ? (
        <ArrowRightIcon
          className={`cb-tree-icon ${open ? 'open' : 'close'}`}
          onClick={() => setOpen(!open)}
        />
      ) : (
        <i className="cb-tree-icon"></i>
      )}
      <div className={`cb-tree-label${props.checkeable ? '' : ' selectable'}`}>
        <label
          className={`${props.checkeable ? 'checkeable' : 'selectable'}`}
          onClick={(e) => props.onClick(e)}
        >
          <input type="checkbox" onChange={handleSelected} />
          <Icon style={{ fontSize: '17px' }}>{props.icon}</Icon>
          <span className="title">{props.name}</span>
        </label>
      </div>
      {props.branch && <ul className="cb-tree-children">{props.children}</ul>}
    </li>
  );
};

TreeItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  branch: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  checkeable: PropTypes.bool,
  icon: PropTypes.string,
};

TreeItem.defaultProps = {
  branch: false,
  checkeable: true,
};

export default TreeItem;
