import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { isLogginIn } from './redux/reducers/app.reducer';

import Login from './pages/Login';
import Home from './pages/Home';

import './css/general.css';
import Loading from './components/common/Loading';

const App = () => {
  const dispatch = useDispatch();
  const { loggedIn, status } = useSelector((state) => state.app);

  useEffect(() => {
    dispatch(isLogginIn());
  }, []);

  if (status === 'checking') {
    return <Loading />;
  }

  if (loggedIn && status === 'authenticated') {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    );
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
