import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Bloque from '../components/common/Bloque';
import Boton from '../components/common/Boton';
import Formulario from '../components/common/Formulario';
import Loading from '../components/common/Loading';
import Texto from '../components/common/Texto';

import { compararFechas, formatoFecha } from '../helpers/Utilitarios';
import { confirmacion, mensaje, mensajeMini } from '../helpers/Mensajes';

import { logoutAction } from '../redux/reducers/app.reducer';
import { patchCambioPasswordUsuarioService } from '../redux/services/usuario.service';

import SaveIcon from '@mui/icons-material/Save';

import '../css/pages/cambio.css';

const Cambio = () => {
  const { currentUser } = useSelector((state) => state.app);
  const [usuario, setUsuario] = useState({
    us: currentUser.us,
    ac: '',
    nu: '',
    nc: '',
  });
  const [errors, setErrors] = useState({ ac: '', nu: '', nc: '' });
  const [process, setProcess] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (item) => {
    const { name, value } = item;
    setUsuario((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    if (usuario.ac === '') {
      mensajeMini('Ingrese contrase&ntilde;a actual');
      return;
    }
    if (usuario.nu === '') {
      mensajeMini('Ingrese contrase&ntilde;a nueva');
      return;
    }
    if (usuario.nc === '') {
      mensajeMini('Ingrese contrase&ntilde;a de confirmaci&oacute;n');
      return;
    }
    setProcess(true);
    patchCambioPasswordUsuarioService(usuario)
      .then(() => {
        mensaje('Operación Satisfactoria');
        dispatch(logoutAction());
      })
      .catch((error) => {
        if (error.data.errors) {
          setErrors(error.data.errors);
        }
      })
      .finally(() => setProcess(false));
  };

  return (
    <Formulario>
      {process && <Loading />}
      <Bloque titulo="Cambio de contrase&ntilde;a">
        <div className="view-cambio">
          {currentUser.fr && currentUser.fr != 0 && (
            <span
              style={{ color: 'red', marginBottom: '10px' }}
            >{`Su contraseña temporal ${
              compararFechas(currentUser.fr) ? 'venció' : 'expira'
            } el ${formatoFecha(currentUser.fr)}`}</span>
          )}
          <Texto
            name="ac"
            value={usuario.ac}
            label="Contrase&ntilde;a actual"
            type="password"
            tabIndex={10}
            error={errors.ac}
            onChange={handleChange}
          />
          <Texto
            name="nu"
            value={usuario.nu}
            label="Contrase&ntilde;a nueva"
            type="password"
            tabIndex={11}
            error={errors.nu}
            onChange={handleChange}
          />
          <Texto
            name="nc"
            value={usuario.nc}
            label="Confirmar contrase&ntilde;a nueva"
            type="password"
            tabIndex={12}
            error={errors.nc}
            onChange={handleChange}
          />
          <Boton
            className="cb-grabar"
            disabled={process}
            icon={() => <SaveIcon />}
            onClick={() =>
              confirmacion('¿Esta seguro?', `De cambiar su contraseña`)
                .then(() => handleSave())
                .catch(() => {})
            }
          >
            Cambiar
          </Boton>
          <h3>Reglas de una contrase&ntilde;a segura</h3>
          <span>1.- Debe contener al menos un n&uacute;mero.</span>
          <span>
            2.- Debe contener letras may&uacute;sculas y min&uacute;sculas.
          </span>
          <span>
            3.- Debe tener un tama&ntilde;o m&iacute;nimo de 8 y m&aacute;ximo
            de 16 d&iacute;gitos.
          </span>
          <span>
            4.- Debe contener al menos uno de estos caracteres: @#$%&+=
          </span>
        </div>
      </Bloque>
    </Formulario>
  );
};

export default Cambio;
