import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Boton from '../../components/common/Boton';
import ComboBox from '../../components/common/ComboBox';
import Modal from '../../components/common/Modal';
import Texto from '../../components/common/Texto';
import { useFormularioContext } from '../../components/common/Formulario';

import useRol from '../../hooks/useRol';
import { confirmacion } from '../../helpers/Mensajes';
import { clienteModel } from '../../redux/models/cliente.model';
import { servicios } from '../../redux/helpers';

import SaveIcon from '@mui/icons-material/Save';

const ClienteItem = () => {
  const { tipoRegistros } = useSelector((state) => state.maestro);
  const {
    selected,
    openModal,
    processing,
    errors,
    callTransaction,
    cancelTransaction,
  } = useFormularioContext();

  const [cliente, setCliente] = useState();
  const { aplicaciones } = useSelector((state) => state.aplicacion);
  const { roles, getRoles, processingRoles } = useRol();

  useEffect(() => {
    setCliente(selected);
    handleChange({ name: 'tp', value: 'N' });
  }, [selected, openModal]);

  const handleChange = (item) => {
    const { name, value } = item;
    setCliente((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (!openModal) {
    return null;
  }

  const handleSave = () => {
    callTransaction({
      method: 'post',
      url: `${servicios.CLIENTE}/potencial`,
      data: cliente,
      model: clienteModel,
    });
  };
  return (
    <Modal
      title={`${cliente.cl === '' ? 'Nuevo' : 'Editar'} cliente`}
      processing={processing}
      onActions={() => (
        <Boton
          className="cb-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={16}
          onClick={() =>
            confirmacion(
              '¿Desea guardar?',
              `${
                cliente.cl === '' ? 'Un nuevo' : 'Una actualización de un'
              } cliente`
            )
              .then(() => handleSave())
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() => cancelTransaction(clienteModel)}
    >
      <ComboBox
        name="tp"
        value={cliente.tp}
        label="Tipo documento"
        size={2}
        tabIndex={10}
        data={[
          { value: 'N', label: 'Natural' },
          { value: 'J', label: 'Jurídica' },
        ]}
        onChange={handleChange}
      />
      <Texto
        name="cu"
        value={cliente.cu}
        label="Correo"
        size={2}
        tabIndex={11}
        format={'email'}
        error={errors.cu}
        onChange={handleChange}
        enabledPaste
      />
      {cliente.tp === 'N' ? (
        <>
          <Texto
            name="nn"
            value={cliente.nn}
            label="Primer nombre"
            size={2}
            tabIndex={12}
            error={errors.nn}
            onChange={handleChange}
          />
          <Texto
            name="sn"
            value={cliente.sn}
            label="Segundo nombre (opcional)"
            size={2}
            tabIndex={13}
            error={errors.sn}
            onChange={handleChange}
          />
          <Texto
            name="ap"
            value={cliente.ap}
            label="Apellido paterno"
            size={2}
            tabIndex={14}
            error={errors.ap}
            onChange={handleChange}
          />
          <Texto
            name="am"
            value={cliente.am}
            label="Apellido materno (opcional)"
            size={2}
            tabIndex={15}
            error={errors.am}
            onChange={handleChange}
          />
        </>
      ) : (
        <>
          <Texto
            name="nr"
            value={cliente.nr}
            label="RUC"
            size={2}
            tabIndex={12}
            restrict={'int'}
            error={errors.nr}
            onChange={handleChange}
          />
          <Texto
            name="rz"
            value={cliente.rz}
            label="Raz&oacute;n social"
            size={2}
            tabIndex={13}
            error={errors.rz}
            onChange={handleChange}
          />
        </>
      )}
      <Texto
        name="nt"
        value={cliente.nt}
        label="N&uacute;mero tel&eacute;fono"
        size={2}
        restrict={'int'}
        format={'phone'}
        tabIndex={16}
        error={errors.nt}
        onChange={handleChange}
      />
      <ComboBox
        name="tr"
        label="Tipo registro"
        value={cliente.tr}
        tabIndex={17}
        size={2}
        data={tipoRegistros.map((i) => ({ value: i.vm, label: i.de }))}
        error={errors.tr}
        onChange={handleChange}
      />
      <ComboBox
        name="aa"
        value={cliente.aa}
        label="Aplicaci&oacute;n"
        data={aplicaciones.map((i) => ({
          value: i.co,
          label: i.na,
        }))}
        size={2}
        tabIndex={18}
        onChange={(item) => {
          handleChange(item);
          handleChange({ name: 'pp', value: [] });
          getRoles(null, item.value);
        }}
      />
      <ComboBox
        name="pp"
        values={cliente.pp?.map((r) => r.ir)}
        label="Perfiles"
        size={2}
        tabIndex={19}
        data={roles.map((i) => ({
          value: i.ir,
          label: i.nr,
        }))}
        disabled={processingRoles}
        multiple
        dropdownTop
        onChange={(item) => {
          let roles = item.map((r) => r.value);
          setCliente((prev) => ({
            ...prev,
            pp: roles,
          }));
        }}
      />
    </Modal>
  );
};

export default ClienteItem;
