import React from 'react';

import Bloque from '../../components/common/Bloque';
import CheckBox from '../../components/common/CheckBox';
import TablaBody from '../../components/common/TablaBody';
import TablaSearch from '../../components/common/TablaSearch';
import Paginacion from '../../components/common/Paginacion';
import { useTablaFilter } from '../../components/common/Formulario';

import EditIcon from '@mui/icons-material/Edit';

const MaestroList = () => {
  const { setSelectedItem } = useTablaFilter();
  const columns = [
    {
      key: 'ma',
      align: 'center',
      content: (item) => (
        <>
          <EditIcon
            onClick={() => setSelectedItem(item)}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </>
      ),
    },
    {
      key: 'co',
      label: 'Código',
    },
    {
      key: 'ds',
      label: 'Descripción',
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];
  return (
    <>
      <Bloque titulo="Lista de maestros">
        <div className="cb-table">
          <TablaSearch />
          <TablaBody columns={columns} />
          <Paginacion />
        </div>
      </Bloque>
    </>
  );
};

export default MaestroList;
