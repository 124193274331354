import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import Loading from './Loading';
import { api } from '../../redux/api';

const ViewFile = ({
  url,
  extension,
  params,
  onClose,
  filename,
  showInline,
}) => {
  const [processing, setProcessing] = useState(false);
  const [urlReport, setUrlReport] = useState('');
  const modalRef = useRef(null);

  useEffect(() => {
    if (showInline === undefined) {
      modalRef.current.focus();
    }
  }, []);

  const hanldeType = () => {
    if (['png', 'jpg', 'jpeg'].includes(extension)) {
      return (
        <img
          src={url}
          alt="Imagen"
          style={{ width: '100%', objectFit: 'cover' }}
        />
      );
    } else if (['doc', 'docx', 'xls', 'xlsx'].includes(extension)) {
      getFile();
      return (
        <a
          href={urlReport}
          download={`${filename}.${extension}`}
          onClick={() => onClose()}
        >
          {`Descargar ${filename}.${extension}`}
        </a>
      );
    } else {
      return null;
    }
  };

  const getFile = () => {
    api
      .get(`${url}`, { responseType: 'blob', params })
      .then((response) => {
        setProcessing(false);
        let fileUrl = window.URL.createObjectURL(new Blob([response]));
        setUrlReport(fileUrl);
      })
      .catch((error) => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: error.status === 500 ? 'error' : 'warning',
          title:
            error.status === 500
              ? 'Error al generar reporte'
              : 'No hay registros',
        });
        onClose();
      });
  };

  return (
    <>
      {showInline ? (
        hanldeType()
      ) : (
        <div className="cb-content-modal" ref={modalRef}>
          <div
            className={`cb-modal ${
              ['doc', 'docx', 'xls', 'xlsx'].includes(extension)
                ? 'op-small'
                : 'op-medium'
            } content-imagen`}
          >
            <div className="cb-modal-header">
              <div className="cb-modal-close" onClick={() => onClose()}></div>
            </div>
            <div className="cb-modal-body">{hanldeType()}</div>
            {processing && <Loading tipo="images" />}
          </div>
        </div>
      )}
    </>
  );
};

ViewFile.propTypes = {
  url: PropTypes.string.isRequired,
  extension: PropTypes.string.isRequired,
  params: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  filename: PropTypes.string,
  showInline: PropTypes.bool,
};

export default ViewFile;
