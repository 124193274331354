import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Proptypes from 'prop-types';

import Boton from './Boton';
import FileUpload from './FileUpload';
import Icon from './Icon';
import Modal from './Modal';
import ViewFile from './ViewFile';
import Texto from './Texto';

import { obtenerIcono } from '../../helpers/Utilitarios';
import { confirmacion, mensajeMini, mensaje } from '../../helpers/Mensajes';
import { api } from '../../redux/api';
import { servicios } from '../../redux/helpers';

import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const Adjunto = ({
  onNew,
  onSuccess,
  stateParent,
  extensions,
  data,
  file,
  processing,
  title,
  hideTitle,
  addButtonText,
  addButtonColor,
  addButtonIcon,
  confirmEditText,
  confirmNewText,
  confirmButtonColor,
  confirmButtonIcon,
  confirmButtonText,
  showInline,
}) => {
  const { currentUser } = useSelector((state) => state.app);
  const [openFile, setOpenFile] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [filename, setFilename] = useState(null);
  const [downloadname, setDownloadname] = useState(null);
  const [extension, setExtension] = useState(null);
  const [documento, setDocumento] = useState(null);
  const [archivo, setArchivo] = useState(null);

  const patchEditarArchivo = (data) => {
    let form_data = new FormData();
    form_data.append('ar', file);
    form_data.append('co', data);
    form_data.append('nr', currentUser.np);
    form_data.append('file', documento);
    api
      .patch(`${servicios.ARCHIVO}/editar`, form_data, {
        headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
      })
      .then(() => handleSuccess())
      .catch((error) => console.log(error.data))
      .finally(() => setOpenNew(false));
  };

  const patchBajaArchivo = (data) => {
    api
      .patch(`${servicios.ARCHIVO}/baja`, { ar: file, co: data })
      .then(() => handleSuccess())
      .catch((error) => console.log(error.data))
      .finally(() => setOpenNew(false));
  };

  const handleSuccess = () => {
    setFilename(null);
    setDocumento(null);
    setArchivo(null);
    setExtension(null);
    setDownloadname(null);
    mensaje('Operación Satisfactoria');
    onSuccess();
  };

  return (
    <>
      <div
        className="cb-form-group"
        style={{
          gridColumn: '1/3',
          borderTop: hideTitle ? 'none' : '1px solid #3498db',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '5px',
          }}
        >
          {hideTitle ? <div></div> : <span>{title}</span>}
          <Boton
            className="cb-dinamic"
            disabled={processing}
            icon={() => (addButtonIcon ? addButtonIcon() : <AttachFileIcon />)}
            tabIndex={25}
            style={{ '--button-color': addButtonColor }}
            onClick={() => setOpenNew(true)}
          >
            {addButtonText}
          </Boton>
        </div>
        {data &&
          data.map((d) => (
            <div
              key={d.id}
              style={{
                display: 'grid',
                gridTemplateColumns: '30px 20px auto',
                columnGap: '5px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  marginRight: '5px',
                }}
              >
                {stateParent && (
                  <EditIcon
                    style={{
                      color: '#1ab394',
                      fontSize: '18px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setArchivo(d);
                      setOpenNew(true);
                    }}
                  />
                )}
                {stateParent && d.state && (
                  <CloseIcon
                    style={{
                      color: 'red',
                      fontSize: '18px',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      confirmacion(
                        '¿Está seguro?',
                        `De anular el archivo ${d.name}.${d.extension}`
                      )
                        .then(() => patchBajaArchivo(d.id))
                        .catch(() => {})
                    }
                  />
                )}
              </div>
              <Icon svg={obtenerIcono(d.extension)} transparent />
              <div
                style={{ cursor: 'pointer', display: 'flex' }}
                onClick={() => {
                  setFilename(d.id);
                  setExtension(d.extension);
                  setOpenFile(true);
                  setDownloadname(d.name);
                }}
              >
                <span
                  style={{
                    textDecoration: `${d.state ? 'none' : 'line-through'}`,
                    color: `${d.state ? 'inherit' : 'red'}`,
                  }}
                >{`${d.name}.${d.extension}`}</span>
              </div>
            </div>
          ))}
      </div>
      {openFile && (
        <ViewFile
          url={`${servicios.ARCHIVO}/${file}/detalle/${filename}`}
          params={{}}
          extension={extension}
          filename={downloadname}
          onClose={() => setOpenFile(false)}
        />
      )}
      {openNew && (
        <Modal
          title={`${archivo ? 'Editar' : 'Nuevo'} archivo`}
          size="small"
          processing={processing}
          loading="files"
          onActions={() => (
            <Boton
              className="cb-dinamic"
              disabled={processing}
              icon={() =>
                confirmButtonIcon ? confirmButtonIcon() : <SaveIcon />
              }
              tabIndex={501}
              style={{ '--button-color': confirmButtonColor }}
              onClick={() => {
                if (documento) {
                  confirmacion(
                    '¿Desea guardar?',
                    `${archivo ? confirmEditText : confirmNewText}`
                  )
                    .then(() => {
                      if (archivo) {
                        patchEditarArchivo(archivo.id);
                      } else {
                        onNew(documento);
                      }
                    })
                    .catch(() => {});
                } else {
                  mensajeMini('Seleccione archivo');
                }
              }}
            >
              {confirmButtonText}
            </Boton>
          )}
          onClose={() => {
            setArchivo(null);
            setOpenNew(false);
          }}
        >
          {archivo && (
            <Texto
              name="nd"
              value={`${archivo.name}.${archivo.extension}`}
              label="Nombre de archivo"
              tabIndex={500}
              disabled
              onChange={() => {}}
            />
          )}
          <FileUpload
            extensions={extensions}
            onSelectedFile={(files) => {
              setDocumento(files.length > 0 ? files[0].file : null);
            }}
          />
        </Modal>
      )}
    </>
  );
};

Adjunto.propTypes = {
  onNew: Proptypes.func.isRequired,
  onSuccess: Proptypes.func.isRequired,
  stateParent: Proptypes.bool.isRequired,
  title: Proptypes.string,
  extensions: Proptypes.arrayOf(
    Proptypes.oneOf(['pdf', 'docx', 'doc', 'xls', 'xlsx', 'jpg', 'jpeg', 'png'])
  ),
  data: Proptypes.arrayOf(
    Proptypes.shape({
      id: Proptypes.string.isRequired,
      name: Proptypes.string.isRequired,
      extension: Proptypes.string.isRequired,
      state: Proptypes.bool.isRequired,
    })
  ),
  file: Proptypes.string,
  addButtonText: Proptypes.string,
  addButtonColor: Proptypes.string,
  addButtonIcon: Proptypes.func,
  confirmEditText: Proptypes.string,
  confirmNewText: Proptypes.string,
  confirmButtonColor: Proptypes.string,
  confirmButtonIcon: Proptypes.func,
  confirmButtonText: Proptypes.string,
  processing: Proptypes.bool,
  hideTitle: Proptypes.bool,
  showInline: Proptypes.bool,
};

Adjunto.defaultProps = {
  extensions: ['pdf', 'jpg', 'jpeg', 'png'],
  processing: false,
  title: 'Archivos adjuntos',
  textNewFile: 'Nuevo archivo',
  confirmEditText: 'Se agregara un nuevo archivo',
  confirmNewText: 'Se agregara un nuevo archivo',
  confirmButtonColor: '#1ab394',
  confirmButtonText: 'Adjuntar archivo',
  addButtonText: 'Nuevo archivo',
  addButtonColor: '#3285a8',
};

export default Adjunto;
