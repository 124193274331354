import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Adjunto from '../../components/common/Adjunto';
import Modal from '../../components/common/Modal';
import { useFormularioContext } from '../../components/common/Formulario';

import { blogModel } from '../../redux/models/blog.model';
import { servicios } from '../../redux/helpers';
import useArchivo from '../../hooks/useArchivo';

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const BlogImage = () => {
  const { currentUser } = useSelector((state) => state.app);
  const {
    selected,
    openModalImage,
    processing,
    callTransaction,
    cancelTransaction,
  } = useFormularioContext();
  const { getArchivo, archive, processingArchivo } = useArchivo();

  useEffect(() => {
    if (selected.adj) {
      getArchivo(selected.adj.ar);
    }
  }, [selected, openModalImage]);

  if (!openModalImage) {
    return null;
  }

  const handleSaveImage = (file) => {
    let form_data = new FormData();
    form_data.append('bl', selected.bl);
    form_data.append('nr', currentUser.np);
    form_data.append('file', file);

    callTransaction({
      method: 'patch',
      url: `${servicios.BLOG}/imagen`,
      data: form_data,
      headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
      model: blogModel,
    });
  };

  return (
    <Modal
      title={`Imagenes del blog`}
      processing={processing}
      onClose={() => cancelTransaction(blogModel)}
    >
      <Adjunto
        extensions={['jpg', 'jpeg', 'png']}
        stateParent={selected.st}
        processing={processing || processingArchivo}
        file={selected.adj && selected.adj.ar}
        data={
          archive &&
          archive.dtl &&
          archive.dtl.map((a) => ({
            id: a.co,
            name: a.na,
            extension: a.ex,
            state: a.st,
          }))
        }
        onNew={(documento) => handleSaveImage(documento)}
        onSuccess={() => getArchivo(selected.adj.ar)}
        addButtonText={'Nueva imagen'}
        confirmButtonText={'Adjuntar imagen'}
        confirmButtonIcon={() => <AddPhotoAlternateIcon />}
        confirmButtonColor={'#3285a8'}
        confirmEditText={'Se modificara la imagen del blog'}
        confirmNewText={'Se agregara una imagen al blog'}
        hideTitle
      />
    </Modal>
  );
};

export default BlogImage;
