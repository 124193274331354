import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Formulario from '../../components/common/Formulario';
import { getMaestroDetalleAction } from '../../redux/actions/maestro.action';
import { servicios } from '../../redux/helpers';
import { rolModel } from '../../redux/models/rol.model';

import RolFilter from './RolFilter';
import RolItem from './RolItem';
import RolList from './RolList';

const Rol = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMaestroDetalleAction('ROLES'));
  }, []);

  const initialState = {
    params: {
      app: '',
    },
    serviceName: servicios.PERFIL,
    selected: rolModel,
    errors: rolModel,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'FILTER_APP':
        return {
          ...state,
          params: { app: action.payload },
        };
      default:
        return state;
    }
  };
  return (
    <Formulario reducer={reducer} initialState={initialState}>
      <RolList />
      <RolFilter />
      <RolItem />
    </Formulario>
  );
};

export default Rol;
