import { createSlice } from '@reduxjs/toolkit';
import { getAplicacionesAction } from '../actions/aplicacion.action';

let initialState = {
  aplicaciones: [],
  processing: false,
};

export const aplicacionReducer = createSlice({
  name: 'aplicacion',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAplicacionesAction.pending, (state) => {
        state.processing = true;
      })
      .addCase(getAplicacionesAction.fulfilled, (state, action) => {
        state.processing = false;
        state.aplicaciones = action.payload;
      })
      .addCase(getAplicacionesAction.rejected, (state) => {
        state.processing = false;
        state.aplicaciones = [];
      });
  },
});

export default aplicacionReducer.reducer;
