import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Formulario, { actions } from '../../components/common/Formulario';
import { getDetalleAction } from '../../redux/actions/detalle.action';
import { servicios } from '../../redux/helpers';
import { blogModel } from '../../redux/models/blog.model';
import { TYPE_FILTER } from '../../redux/types/app.types';

import BlogFilter from './BlogFilter';
import BlogItem from './BlogItem';
import BlogList from './BlogList';

const Blog = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDetalleAction('CATEGORIA'));
    dispatch(getDetalleAction('SEGMENTO'));
  }, []);

  const initialState = {
    params: {
      st: '',
      fi: '',
      ff: '',
    },
    serviceName: servicios.BLOG,
    selected: blogModel,
    errors: blogModel,
    openModalImage: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'ADD IMAGE':
        return {
          ...state,
          openModalImage: true,
          selected: action.payload,
          errors: blogModel,
        };
      case TYPE_FILTER:
        return {
          ...state,
          params: {
            st: action.payload.st,
            fi: action.payload.fi,
            ff: action.payload.ff,
          },
        };
      case actions.TRANSACTION_CANCEL:
        return {
          ...state,
          openModalImage: false,
        };
      default:
        return state;
    }
  };
  return (
    <Formulario reducer={reducer} initialState={initialState}>
      <BlogList />
      <BlogFilter />
      <BlogItem />
    </Formulario>
  );
};

export default Blog;
