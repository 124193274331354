import { useState } from 'react';
import { useSelector } from 'react-redux';
import { mensaje } from '../helpers/Mensajes';
import { api } from '../redux/api';
import { servicios } from '../redux/helpers';

const useArchivo = () => {
  const { currentUser } = useSelector((state) => state.app);
  const [processingArchivo, setProcessingArchivo] = useState(false);
  const [archive, setArchive] = useState();

  const getArchivo = (ar) => {
    setProcessingArchivo(true);
    api
      .get(`${servicios.ARCHIVO}/${ar}`)
      .then((response) => setArchive(response))
      .catch((error) => console.log(error.data))
      .finally(() => setProcessingArchivo(false));
  };

  const patchEditarArchivo = (data) => {
    let form_data = new FormData();
    form_data.append('ar', data.ar);
    form_data.append('co', data.co);
    form_data.append('nr', currentUser.np);
    form_data.append('file', data.file);
    setProcessingArchivo(true);
    api
      .patch(`${servicios.ARCHIVO}/editar`, form_data, {
        headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
      })
      .then(() => mensaje('Operación Satisfactoria'))
      .catch((error) => console.log(error.data))
      .finally(() => setProcessingArchivo(false));
  };

  const patchBajaArchivo = (data) => {
    setProcessingArchivo(true);
    api
      .patch(`${servicios.ARCHIVO}/baja`, data)
      .then(() => mensaje('Operación Satisfactoria'))
      .catch((error) => console.log(error.data))
      .finally(() => setProcessingArchivo(false));
  };

  return {
    processingArchivo,
    patchEditarArchivo,
    patchBajaArchivo,
    getArchivo,
    archive,
  };
};

export default useArchivo;
