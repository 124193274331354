export const rolModel = {
  ir: '',
  em: '',
  ne: '',
  ap: '',
  na: '',
  nr: '',
  dr: '',
  tr: '',
  nt: '',
  st: false,
};
