import React, { useState } from 'react';
import { mensaje } from '../helpers/Mensajes';

import { api } from '../redux/api';
import { servicios } from '../redux/helpers';

const useAcceso = () => {
  const [accesos, setAccesos] = useState([]);
  const [processingAcceso, setProcessingAcceso] = useState(false);

  const getAccesos = (ap, st) => {
    setProcessingAcceso(true);
    api
      .get(servicios.ACCESO, { params: { ap, st } })
      .then((response) => setAccesos(response))
      .catch(() => setAccesos([]))
      .finally(() => setProcessingAcceso(false));
  };

  const postAcceso = (items) => {
    setProcessingAcceso(true);
    api
      .post(servicios.ACCESO, items)
      .then(() => mensaje('Operación Satisfactoria'))
      .catch((error) => console.log(error.data))
      .finally(() => setProcessingAcceso(false));
  };
  return {
    accesos,
    processingAcceso,
    getAccesos,
    postAcceso,
  };
};

export default useAcceso;
